import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";

export default class DescriptionMessagePopup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: this.props.initialName || "",
      description: ""
    };

    this.handleChange = this.handleChange.bind(this);
    this.getNameError = this.getNameError.bind(this);
    this.getDescriptionError = this.getDescriptionError.bind(this);
  }

  handleChange = event => {
    var vals = {};
    vals[event.target.id] = event.target.value;
    this.setState(vals);
  };

  getNameError() {
    const name = this.state.name;
    if (name.trim().length === 0) return "Please enter a name";
    if (name.trim().length < 5) return "Name is too short";
    else return "";
  }

  getDescriptionError() {
    const description = this.state.description;
    if (description.trim().length === 0) return "Please enter a description";
    if (description.trim().length < 5) return "Description is too short";
    else return "";
  }

  submit() {
    this.props.submit({
      name: this.state.name,
      description: this.state.description
    });
  }

  render() {
    const nameError = this.getNameError();
    const descriptionError = this.getDescriptionError();
    const submitEnabled =
      nameError.length === 0 && descriptionError.length === 0;

    return (
      <div className="OkMessagePopup">
        <Modal show={true} onHide={this.props.onClose} size="lg">
          <Modal.Header closeButton>
            <div className="text-success">{this.props.heading}</div>
          </Modal.Header>

          <Modal.Body>
            <div>{this.props.message}</div>
            <div className="form-group">
              <label>Name:</label>
              <input
                className="form-control"
                id="name"
                value={this.state.name}
                onChange={this.handleChange}
              />
              <div className="text-danger">{nameError}</div>
            </div>
            <div className="form-group">
              <label>Description:</label>
              <input
                className="form-control"
                id="description"
                value={this.state.description}
                onChange={this.handleChange}
              />
              <div className="text-danger">{descriptionError}</div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            {submitEnabled && (
              <Button variant="success" onClick={() => this.submit()}>
                OK
              </Button>
            )}
            {!submitEnabled && (
              <Button variant="success" disabled>
                OK
              </Button>
            )}
            <span> </span>
            <Button variant="danger" onClick={this.props.onClose}>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}
