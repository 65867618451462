import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";
import { connect } from "react-redux";
import { hideErrorMessage } from "../../../reduxStores/actions/PopupMessagesActions";

class ErrorMessagePopup extends Component {
  render() {
    let visible = this.props.errorMessage != null;

    return (
      <div className="ErrorMessagePopup">
        <Modal show={visible} onHide={this.props.hideErrorMessage} size="lg">
          <Modal.Header closeButton>
            <h4 className="text-danger">Error</h4>
          </Modal.Header>

          <Modal.Body>{this.props.errorMessage}</Modal.Body>
          <Modal.Footer>
            <Button variant="success" onClick={this.props.hideErrorMessage}>
              OK
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  errorMessage: state.popupMessages.errorMessage
});

export default connect(mapStateToProps, {
  hideErrorMessage
})(ErrorMessagePopup);
