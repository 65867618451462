import { API, Storage } from "aws-amplify";

export async function sendEmail(firstName, lastName, email, phone, query) {
  try {
    let result = await API.post("theagilelife-app-api", "/send_email", {
      body: {
        firstname: firstName,
        lastname: lastName,
        email: email,
        phone: phone,
        query: query,
      },
    });
  } catch (e) {
    alert(
      "sorry ... there was an error sending your message. Please try emailing us directly at enquiries@theagilelife.com.au"
    );
  }
}

export async function sendWebinarLink(firstName, lastName, email) {
  let result = await API.post("theagilelife-app-api", "/webinar_subscribe", {
    body: {
      firstName: firstName,
      lastName: lastName,
      email: email,
    },
  });
  return result;
}
