import React, { Component } from "react";
import "./App.css";
import store from "./reduxStores/store.js";
import { Provider } from "react-redux";
import Amplify, { Auth } from "aws-amplify";
import config from "./config";
import Main from "./main";
import ReactPixel from "react-facebook-pixel";

import { library } from "@fortawesome/fontawesome-svg-core";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { faArrowsAlt } from "@fortawesome/free-solid-svg-icons";
import { faChartPie } from "@fortawesome/free-solid-svg-icons";
import { faChartLine } from "@fortawesome/free-solid-svg-icons";
import { faSquare } from "@fortawesome/free-solid-svg-icons";
import { faCheckSquare } from "@fortawesome/free-regular-svg-icons";
import { faPlusSquare } from "@fortawesome/free-solid-svg-icons";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import { faPlay } from "@fortawesome/free-solid-svg-icons";
import { faStop } from "@fortawesome/free-solid-svg-icons";
import { faShare } from "@fortawesome/free-solid-svg-icons";
import { faCog } from "@fortawesome/free-solid-svg-icons";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { faCalendar } from "@fortawesome/free-solid-svg-icons";
import { faSync } from "@fortawesome/free-solid-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faAlignLeft } from "@fortawesome/free-solid-svg-icons";
import { faAlignRight } from "@fortawesome/free-solid-svg-icons";
import { faAlignCenter } from "@fortawesome/free-solid-svg-icons";
import { faAlignJustify } from "@fortawesome/free-solid-svg-icons";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { faFillDrip } from "@fortawesome/free-solid-svg-icons";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import { faUndo } from "@fortawesome/free-solid-svg-icons";
import { faRedo } from "@fortawesome/free-solid-svg-icons";

library.add(faSpinner);
library.add(faCheck);
library.add(faSync);
library.add(faCheckSquare);
library.add(faTimes);
library.add(faBars);
library.add(faTimesCircle);
library.add(faArrowsAlt);
library.add(faChartPie);
library.add(faChartLine);
library.add(faSquare);
library.add(faPlusSquare);
library.add(faSearch);
library.add(faCircle);
library.add(faPlay);
library.add(faStop);
library.add(faShare);
library.add(faCog);
library.add(faInfoCircle);
library.add(faChevronLeft);
library.add(faCalendar);
library.add(faEnvelope);
library.add(faAlignLeft);
library.add(faAlignRight);
library.add(faAlignCenter);
library.add(faAlignJustify);
library.add(faCaretDown);
library.add(faFillDrip);
library.add(faPencilAlt);
library.add(faUndo);
library.add(faRedo);

Amplify.configure({
  Auth: {
    mandatorySignIn: false,
    region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    identityPoolId: config.cognito.IDENTITY_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID,
  },
  /*Storage: {
    region: config.s3.REGION,
    bucket: config.s3.BUCKET,
    identityPoolId: config.cognito.IDENTITY_POOL_ID,
  },*/
  API: {
    aws_appsync_graphqlEndpoint: config.appSync.ENDPOINT,
    aws_appsync_region: config.appSync.REGION,
    aws_appsync_authenticationType: "AWS_IAM",
    endpoints: [
      {
        name: "theagilelife-app-api",
        endpoint: config.apiGateway.URL,
        region: config.apiGateway.REGION,
      },
    ],
    /*endpoints: [
      {
        name: "iknow-app-api",
        endpoint: config.apiGateway.URL,
        region: config.apiGateway.REGION
      },
      {
        name: "iknow-app-api2",
        endpoint: config.apiGateway2.URL,
        region: config.apiGateway2.REGION
      }
    ]*/
  },
});

class App extends Component {
  componentDidMount() {
    const advancedMatching = {}; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
    const options = {
      autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
      debug: false, // enable logs
    };
    ReactPixel.init("996125550924861", advancedMatching, options);

    ReactPixel.pageView(); // For tracking page view
    console.log("pixel activated");
  }

  render() {
    return (
      <Provider store={store}>
        <Main />
      </Provider>
    );
  }
}

export default App;
