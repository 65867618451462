import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import "./ImageSelectorPopup.css";
import FileDrop from "react-file-drop";
import MessageServer from "./MessageServer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { v4 as uuidv4 } from "uuid";
import { Storage } from "aws-amplify";
import { connect } from "react-redux";
import { showErrorMessage } from "../../../reduxStores/actions/PopupMessagesActions";

class ImageSelectorPopup extends Component {
  constructor(props) {
    super(props);

    let crop = {
      width: 100,
      height: 100,
      x: 0,
      y: 0,
    };
    //alert("props.aspect = " + props.aspect);
    if (props.aspect) crop.aspect = props.aspect;

    this.state = {
      progress: 0,
      isUploading: false,
      panel: "selectFile",
      src: null,
      crop: crop,
    };

    this.imageLoaded = this.imageLoaded.bind(this);
    this.handleDrop = this.handleDrop.bind(this);
    this.fileSelect = this.fileSelect.bind(this);
    this.pasteFromClipboard = this.pasteFromClipboard.bind(this);
  }

  componentDidMount() {
    window.addEventListener("paste", this.pasteFromClipboard);
  }

  componentWillUnmount() {
    window.removeEventListener("paste", this.pasteFromClipboard);
  }

  startPage() {
    this.setState({ panel: "selectFile" });
  }

  pasteFromClipboard(event) {
    var items = (event.clipboardData || event.originalEvent.clipboardData)
      .items;

    let _this = this;

    for (var i = 0; i < items.length; i++) {
      var item = items[i];
      if (item.kind === "file" && item.type.startsWith("image")) {
        var blob = item.getAsFile();
        var reader = new FileReader();

        reader.onload = function () {
          _this.imageLoaded(reader.result);
        };
        reader.readAsDataURL(blob);

        break;
      }
    }
  }

  imageLoaded(image) {
    this.setState({
      panel: "cropImage",
      src: image,
    });

    /*let im = new Image();
    let _this = this;
    im.onload = function () {
      const canvas = document.createElement("canvas");
      canvas.width = im.width;
      canvas.height = im.height;
      const ctx = canvas.getContext("2d");

      ctx.drawImage(im, 0, 0);

      _this.setState({
        croppedCanvas: canvas,
      });

      if (_this.state.crop.aspect) {
        let s = Math.min(im.width, im.height);
        _this.getCroppedImg(im, { x: 0, y: 0, width: s, height: s });
      } else {
        _this.getCroppedImg(im, {
          x: 0,
          y: 0,
          width: im.width,
          height: im.height,
        });
      }
    };

    im.src = image;*/
  }

  fileSelect = function (files) {
    //alert("file selected!");
    var file = files[0];
    //you will get the file object here
    var type = file.type;
    var allowableTypes = "image/png, image/jpeg, image/gif, image/heic";
    if (allowableTypes.indexOf(type) < 0) {
      this.props.showErrorMessage(
        "unknown image type - only png, jpeg, heic and gif images allowed"
      );

      return;
    }

    var reader = new FileReader();
    let _this = this;

    reader.onload = function (_file) {
      _this.imageLoaded(reader.result);
    };
    reader.readAsDataURL(file);
  };

  handleDrop = (files, event) => {
    var file = files[0];
    //you will get the file object here
    var type = file.type;
    var allowableTypes = "image/png, image/jpeg, image/gif";
    if (allowableTypes.indexOf(type) < 0) {
      this.props.showErrorMessage(
        "unknown image type - only png, jpeg and gif images allowed"
      );
      return;
    }

    var reader = new FileReader();
    let _this = this;

    reader.onload = function (_file) {
      _this.imageLoaded(reader.result);
    };
    reader.readAsDataURL(file);
  };

  onImageLoaded = (image, pixelCrop) => {
    this.imageRef = image;

    // Make the library regenerate aspect crops if loading new images.
    const { crop } = this.state;

    let cropVals = {
      width: 100,
      height: 100,
      x: 0,
      y: 0,
    };
    if (this.props.square) cropVals.aspect = 1;

    this.setState({
      cropVals: crop,
    });

    //if (crop.aspect && crop.height && crop.width) {
    // this.setState({
    //   crop: { ...crop, height: null }
    //  });
    //} else {
    this.makeClientCrop(crop, pixelCrop);
    //}
  };

  onCropComplete = (crop, pixelCrop) => {
    this.makeClientCrop(crop, pixelCrop);
  };

  onCropChange = (crop) => {
    this.setState({ crop });
  };

  async makeClientCrop(crop, pixelCrop) {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = await this.getCroppedImg(
        this.imageRef,
        pixelCrop,
        "newFile.jpeg"
      );
    }
  }

  getCroppedImg(image, pixelCrop, fileName) {
    const canvas = document.createElement("canvas");
    canvas.width = pixelCrop.width;
    canvas.height = pixelCrop.height;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      image,
      pixelCrop.x,
      pixelCrop.y,
      pixelCrop.width,
      pixelCrop.height,
      0,
      0,
      pixelCrop.width,
      pixelCrop.height
    );

    this.setState({
      croppedCanvas: canvas,
    });
  }

  scaleCanvas(canvas) {
    let maxWidth = this.props.maxWidth;
    let originalWidth = canvas.width;
    let originalHeight = canvas.height;
    console.log("scaling image");
    console.log("maxWidth = " + maxWidth);
    console.log("originalWidth = " + originalWidth);

    if (maxWidth && originalWidth / maxWidth > 2) {
      console.log("scaling image by 0.5");
      let newWidth = Math.floor(originalWidth / 2);
      let newHeight = Math.floor(originalHeight / 2);

      let newCanvas = document.createElement("canvas");
      newCanvas.height = newHeight;
      newCanvas.width = newWidth;
      let ctx = newCanvas.getContext("2d");
      ctx.drawImage(
        canvas,
        0,
        0,
        originalWidth,
        originalHeight,
        0,
        0,
        newWidth,
        newHeight
      );

      return this.scaleCanvas(newCanvas); // repeat until done
    } else if (maxWidth && originalWidth > maxWidth) {
      var scale = maxWidth / originalWidth;
      let newWidth = maxWidth;
      let newHeight = Math.floor(originalHeight * scale);

      let newCanvas = document.createElement("canvas");
      newCanvas.height = newHeight;
      newCanvas.width = newWidth;
      let ctx = newCanvas.getContext("2d");
      ctx.drawImage(
        canvas,
        0,
        0,
        originalWidth,
        originalHeight,
        0,
        0,
        newWidth,
        newHeight
      );
      return newCanvas;
    } else return canvas;
  }

  async saveImage() {
    var filename = uuidv4() + ".jpg";
    this.setState({
      isUploading: true,
    });

    let canvas = this.state.croppedCanvas;

    canvas = this.scaleCanvas(canvas);
    console.log("saving canvas w=" + canvas.width);

    // step 2, lets upload a 150x150 image
    // lets post it,
    var dataURL = canvas.toDataURL("image/jpeg", 0.9);

    console.log("uploading");
    var buf = new Buffer(
      dataURL.replace(/^data:image\/\w+;base64,/, ""),
      "base64"
    );

    await Storage.put("images/" + filename, buf, {
      level: "public",
      contentType: "image/png",
      ContentEncoding: "base64",
      Acl: "public-read",
    });

    this.setState({
      isUploading: false,
      panel: "selectFile",
    });

    this.props.onImageUpload({
      resourceId: "images/" + filename,
      width: canvas.width,
      height: canvas.height,
    });
    this.props.onClose();
  }

  render() {
    const { src, crop } = this.state;
    return (
      <div className="OkMessagePopup">
        <Modal show={true} onHide={this.props.onClose} size="lg">
          <Modal.Header closeButton>
            <h5>Image Selector</h5>
          </Modal.Header>

          <Modal.Body>
            {this.state.panel === "selectFile" && (
              <div id="selectImageDiv">
                <p>
                  Please drag and drop your image to the area below, or select
                  from from file.
                </p>
                <div className="dropArea">
                  <FileDrop onDrop={this.handleDrop}>
                    Drop some files here!
                  </FileDrop>
                </div>
                <br />
                <div>
                  <p>
                    <b>OR</b>
                  </p>

                  <form>
                    <input
                      id="fileSelectInput"
                      type="file"
                      onChange={(e) => this.fileSelect(e.target.files)}
                    />
                    <button
                      type="reset"
                      id="imageUploaderFileReset"
                      className="d-none"
                    />
                  </form>
                </div>
                <div>
                  <p>
                    <b>OR</b>
                  </p>
                  Paste from Clipboard (use Ctrl-V on Windows, Command-V on Mac)
                </div>
              </div>
            )}
            {this.state.panel === "cropImage" && (
              <div style={{ height: "300px" }}>
                <ReactCrop
                  src={src}
                  crop={crop}
                  onImageLoaded={this.onImageLoaded}
                  onComplete={this.onCropComplete}
                  onChange={this.onCropChange}
                />
              </div>
            )}
          </Modal.Body>
          <Modal.Footer>
            {!this.state.isUploading && this.state.panel === "cropImage" && (
              <div>
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => this.startPage()}
                  style={{ float: "left" }}
                >
                  <FontAwesomeIcon icon={faChevronLeft} />
                  <span> </span>
                  Back
                </button>
                <span> </span>
                <button
                  type="button"
                  className="btn btn-success"
                  onClick={() => this.saveImage()}
                >
                  Save Image
                </button>
              </div>
            )}
            {this.state.isUploading && (
              <div id="progress">
                uploading file{" "}
                <FontAwesomeIcon icon="spinner" className="fa-spin" />
              </div>
            )}
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, { showErrorMessage })(
  ImageSelectorPopup
);
