import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import smallCircle from "./images/smallcircle.png";
//import AOS from "aos";
import "../../node_modules/aos/dist/aos.css";
import titleHeader from "../components/headers/titleHeader";
import renderFooter from "../components/headers/footer";

export default function Homepage(props) {
  function renderHeaderMenu() {
    return (
      <Row>
        <Col md={6}>
          <ul>
            <li>Agile Methodologies</li>
            <li>Design and Systems Thinking</li>
            <li>The Psychology and Neuroscience of High Performance</li>
          </ul>
        </Col>
        <Col md={6}>
          <ul>
            <li>Customised Leadership Development Programs</li>
            <li>Workshops, Keynotes and Facilitation</li>
            <li>Executive Coaching</li>
          </ul>
        </Col>
      </Row>
    );
  }

  function renderHeader() {
    let header = titleHeader(
      "The Agile Life",
      "We help leaders, teams, and organisations succeed in volatile, disruptive and uncertain times using proven evidence-based strategies."
    );
    return (
      <div>
        {header}
        <div
          className="text-center pageSubheader"
          data-aos="fade-right"
          data-aos-duration="1000"
        >
          <div className="headerMenu text-left ">{renderHeaderMenu()}</div>
        </div>
      </div>
    );
  }

  function renderContactButtons() {
    return (
      <Container>
        <Row>
          <Col md={4}>
            <div
              className="lowerButton"
              onClick={() => props.onSelect("#services")}
            >
              Tell Me More
            </div>
          </Col>
          <Col md={4}>
            <div
              className="lowerButton"
              onClick={() => props.onSelect("#about")}
            >
              About Us
            </div>
          </Col>
          <Col md={4}>
            <div
              className="lowerButton"
              onClick={() => props.onSelect("#contact")}
            >
              Contact Us
            </div>
          </Col>
        </Row>
      </Container>
    );
  }

  return (
    <div className="frontPageContent">
      {renderHeader()}
      <div style={{ borderTop: "3px solid white" }}>
        <div className="image-and-content">
          <div className="image-and-content__image">
            <img src="images/mountain.jpg" />
          </div>
          <div className="image-and-content__container paddedEdges">
            <div className="image-and-content__content">
              <div data-aos="fade-up" data-aos-duration="1000">
                <h4>The Challenge</h4>
                <p>
                  Today's volatile and uncertain world is throwing complex
                  challenges at us at an unprecedented rate and scale.
                </p>
                <p>
                  Last week your workforce was coming to the office, this week
                  they can’t. Revenues are unstable, suppliers are faltering,
                  teams and their managers are stretched.
                </p>
                <p>
                  In the face of all this you still need to deliver, you still
                  need to be there for your customers, you still need to be
                  there for your people.
                </p>
                <p>
                  The main issue is that these challenges can seem overwhelming
                  and intractable, because you cannot think or plan your way to
                  a solution.
                </p>
                <p>
                  And you are right to think this because in a volatile and
                  complex world, it is almost impossible to know what may or may
                  not work best in advance.
                </p>
                <p>But you know you can’t stay still, what is the answer?</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="blueSection"
        style={{ borderTop: "3px solid rgb(6, 14, 46)" }}
      >
        <div className="pictureColumnBox__picture">
          <img src="images/compass6.jpeg" />
        </div>
        <div className="image-and-content__container whiteBox">
          <div className="pictureColumnBox">
            <div className="pictureColumnBox__content">
              <div
                style={{
                  borderLeft: "2px solid orange",
                  paddingLeft: "15px",
                }}
              >
                <div data-aos="fade-left" data-aos-duration="1000">
                  <h4>A Way Forward</h4>
                  <p>
                    We may not know what solution will work in advance but we do
                    know the kinds of approaches that are most likely to
                    succeed.
                  </p>
                  <p>
                    Rather than analysing our way forward we must build our way
                    forward. Single huge, sink or swim bets run the risk of
                    failing catastrophically.
                  </p>
                  <p>
                    Successful leaders in a volatile and complex world rapidly{" "}
                    explore many options in ways that make failure safe, they
                    stand ready to scale what works and have the courage to
                    leave aside what doesn't.
                  </p>
                  <p>
                    In software design, these approaches are known as Agile,
                    whereas in product design they are known as Design Thinking.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div style={{ borderTop: "3px solid white" }}>
        <div className="blackOnWhiteText">
          <div className="image-and-content__container paddedEdges paddedTopAndBottom">
            <div className="blackBox">
              <div className="orangeBox">
                <div className="orangeBox__image">
                  <img src="images/creativeteam.jpg" />
                </div>
                <div className="orangeBox__content">
                  <div data-aos="fade-right" data-aos-duration="1000">
                    <h4>How We Can Help</h4>
                    <p>
                      What we do, is help you apply Design Thinking and Agile
                      Methodologies to your most pressing business challenges,
                      from leadership challenges through to refining your
                      business model.
                    </p>
                    <p>
                      We take the best and most current evidence-based
                      approaches taught at the world's leading organisations and
                      business schools, and help you apply them to your
                      business.
                    </p>
                    <p>
                      Instead of offering generic leadership courses and
                      workshops, we provide "just-in-time" learning
                      opportunities, teaching you what you most need to know,
                      when you most need to know it.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            {renderContactButtons()}
          </div>
        </div>
      </div>

      {renderFooter()}
    </div>
  );
}
