import React, { Component } from "react";
import { Auth } from "aws-amplify";
import LoaderButton from "../buttons/LoaderButton";
import config from "../../config";
import { connect } from "react-redux";
import { onSignIn } from "../../reduxStores/actions/userProfileActions";

const Constants = {
  AUTH_SOURCE_KEY: "amplify-react-auth-source",
  AUTH0: "auth0",
  GOOGLE: "google",
  FACEBOOK: "facebook",
  AMAZON: "amazon",
  REDIRECTED_FROM_HOSTED_UI: "amplify-redirected-from-hosted-ui",
};

var __assign =
  (this && this.__assign) ||
  function () {
    __assign =
      Object.assign ||
      function (t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
          s = arguments[i];
          for (var p in s)
            if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
        }
        return t;
      };
    return __assign.apply(this, arguments);
  };
var __awaiter =
  (this && this.__awaiter) ||
  function (thisArg, _arguments, P, generator) {
    function adopt(value) {
      return value instanceof P
        ? value
        : new P(function (resolve) {
            resolve(value);
          });
    }
    return new (P || (P = Promise))(function (resolve, reject) {
      function fulfilled(value) {
        try {
          step(generator.next(value));
        } catch (e) {
          reject(e);
        }
      }
      function rejected(value) {
        try {
          step(generator["throw"](value));
        } catch (e) {
          reject(e);
        }
      }
      function step(result) {
        result.done
          ? resolve(result.value)
          : adopt(result.value).then(fulfilled, rejected);
      }
      step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
  };
var __generator =
  (this && this.__generator) ||
  function (thisArg, body) {
    var _ = {
        label: 0,
        sent: function () {
          if (t[0] & 1) throw t[1];
          return t[1];
        },
        trys: [],
        ops: [],
      },
      f,
      y,
      t,
      g;
    return (
      (g = { next: verb(0), throw: verb(1), return: verb(2) }),
      typeof Symbol === "function" &&
        (g[Symbol.iterator] = function () {
          return this;
        }),
      g
    );
    function verb(n) {
      return function (v) {
        return step([n, v]);
      };
    }
    function step(op) {
      if (f) throw new TypeError("Generator is already executing.");
      while (_)
        try {
          if (
            ((f = 1),
            y &&
              (t =
                op[0] & 2
                  ? y["return"]
                  : op[0]
                  ? y["throw"] || ((t = y["return"]) && t.call(y), 0)
                  : y.next) &&
              !(t = t.call(y, op[1])).done)
          )
            return t;
          if (((y = 0), t)) op = [op[0] & 2, t.value];
          switch (op[0]) {
            case 0:
            case 1:
              t = op;
              break;
            case 4:
              _.label++;
              return { value: op[1], done: false };
            case 5:
              _.label++;
              y = op[1];
              op = [0];
              continue;
            case 7:
              op = _.ops.pop();
              _.trys.pop();
              continue;
            default:
              if (
                !((t = _.trys), (t = t.length > 0 && t[t.length - 1])) &&
                (op[0] === 6 || op[0] === 2)
              ) {
                _ = 0;
                continue;
              }
              if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) {
                _.label = op[1];
                break;
              }
              if (op[0] === 6 && _.label < t[1]) {
                _.label = t[1];
                t = op;
                break;
              }
              if (t && _.label < t[2]) {
                _.label = t[2];
                _.ops.push(op);
                break;
              }
              if (t[2]) _.ops.pop();
              _.trys.pop();
              continue;
          }
          op = body.call(thisArg, _);
        } catch (e) {
          op = [6, e];
          y = 0;
        } finally {
          f = t = 0;
        }
      if (op[0] & 5) throw op[1];
      return { value: op[0] ? op[1] : void 0, done: true };
    }
  };

class GoogleButton extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
    };
    //this.fbAsyncInit = this.fbAsyncInit.bind(this);
    //this.initFB = this.initFB.bind(this);
    this.handleError = this.handleError.bind(this);
    //this.statusChangeCallback = this.statusChangeCallback.bind(this);
    this.onStateChange = this.onStateChange.bind(this);
  }

  async componentDidMount() {
    var ga =
      window.gapi && window.gapi.auth2
        ? window.gapi.auth2.getAuthInstance()
        : null;
    if (!ga) this.createScript();
  }

  createScript = function () {
    var script = document.createElement("script");
    script.src = "https://apis.google.com/js/platform.js";
    script.async = true;
    script.onload = this.initGapi;
    document.body.appendChild(script);
  };

  initGapi = function () {
    var that = this;
    var google_client_id = config.social.GOOGLE;
    var g = window.gapi;
    g.load("auth2", function () {
      g.auth2.init({
        client_id: google_client_id,
        scope: "profile email openid",
      });
    });
  };

  /*initFB() {
    const fb = window.FB;
    console.log("FB inited");
  }*/

  /*statusChangeCallback = (response) => {
    if (response.status === "connected") {
      this.handleResponse(response.authResponse);
    } else {
      this.handleError(response);
      this.setState({ isLoading: false });
    }
  };*/

  /*checkLoginState = () => {
    window.FB.getLoginStatus(this.statusChangeCallback);
  };*/

  handleClick = () => {
    this.setState({ isLoading: true });
    var _this = this;
    var ga = window.gapi.auth2.getAuthInstance();
    var onError = this.handleError;
    ga.signIn().then(
      function (googleUser) {
        _this.federatedSignIn(googleUser);
        var payload = {
          provider: Constants.GOOGLE,
        };
        try {
          localStorage.setItem(
            Constants.AUTH_SOURCE_KEY,
            JSON.stringify(payload)
          );
        } catch (e) {
          //logger.debug("Failed to cache auth source into localStorage", e);
        }
      },
      function (error) {
        if (onError) onError(error);
        else throw error;
      }
    );
  };

  federatedSignIn = function (googleUser) {
    return __awaiter(this, void 0, void 0, function () {
      var _a, id_token, expires_at, profile, user, onStateChange;
      return __generator(this, function (_b) {
        //alert("generator _b=" + JSON.stringify(_b));
        switch (_b.label) {
          case 0:
            _a = googleUser.getAuthResponse();
            id_token = _a.id_token;
            expires_at = _a.expires_at;
            profile = googleUser.getBasicProfile();
            //alert("profile = " + JSON.stringify(profile));

            user = {
              id: "google:" + profile.getId(),
              email: profile.getEmail(),
              name: profile.getName(),
              profileImage: profile.getImageUrl(),
              given_name: profile.getGivenName(),
              family_name: profile.getFamilyName(),
            };
            onStateChange = this.onStateChange;
            if (
              !Auth ||
              typeof Auth.federatedSignIn !== "function" ||
              typeof Auth.currentAuthenticatedUser !== "function"
            ) {
              throw new Error(
                "No Auth module found, please ensure @aws-amplify/auth is imported"
              );
            }
            return [
              4 /*yield*/,
              Auth.federatedSignIn(
                "google",
                { token: id_token, expires_at: expires_at },
                user
              ),
            ];
          case 1:
            _b.sent();
            return [4 /*yield*/, Auth.currentAuthenticatedUser()];
          case 2:
            user = _b.sent();
            if (onStateChange) {
              onStateChange("signedIn", user);
            }
            return [2 /*return*/];
          default:
            return;
        }
      });
    });
  };

  onStateChange(action, user) {
    if (action === "signedIn") {
      this.props.onSignIn();
      this.setState({ isLoading: false });
    }
  }

  handleError(error) {
    alert(JSON.stringify(error));
    this.setState({ isLoading: false });
  }

  /*async handleResponse(data) {
    const { accessToken: token, expiresIn } = data;
    const expires_at = expiresIn * 1000 + new Date().getTime();

    this.setState({ isLoading: true });

    var promise = new Promise(function (resolve, reject) {
      window.FB.api(
        "/me",
        { fields: "name,email,first_name,last_name,picture" },
        function (response) {
          // response has the data
          resolve(response);
        }
      );
    });

    let response = await promise;
    let picture = response.picture;

    picture = picture.data.url;
    const facebook_user = {
      id: "facebook:" + data.userID,
      name: response.name,
      email: response.email,
      given_name: response.first_name,
      family_name: response.last_name,
      profileImage: picture,
    };

    try {
      const response = await Auth.federatedSignIn(
        "facebook",
        { token, expires_at },
        facebook_user
      );

      Auth.currentAuthenticatedUser().then((user) => {
        this.props.onSignIn();
        this.setState({ isLoading: false });
      });
    } catch (e) {
      this.setState({ isLoading: false });
      this.handleError(e);
    }
  }*/

  render() {
    let googleText = (
      <span>
        <div
          style={{ width: "20px", display: "inline-block", fontSize: "20px" }}
        >
          <svg
            viewBox="0 0 256 262"
            xmlns="http://ww0w.w3.org/2000/svg"
            preserveAspectRatio="xMidYMid"
          >
            <path
              d="M255.878 133.451c0-10.734-.871-18.567-2.756-26.69H130.55v48.448h71.947c-1.45 12.04-9.283 30.172-26.69 42.356l-.244 1.622 38.755 30.023 2.685.268c24.659-22.774 38.875-56.282 38.875-96.027"
              fill="#4285F4"
            />
            <path
              d="M130.55 261.1c35.248 0 64.839-11.605 86.453-31.622l-41.196-31.913c-11.024 7.688-25.82 13.055-45.257 13.055-34.523 0-63.824-22.773-74.269-54.25l-1.531.13-40.298 31.187-.527 1.465C35.393 231.798 79.49 261.1 130.55 261.1"
              fill="#34A853"
            />
            <path
              d="M56.281 156.37c-2.756-8.123-4.351-16.827-4.351-25.82 0-8.994 1.595-17.697 4.206-25.82l-.073-1.73L15.26 71.312l-1.335.635C5.077 89.644 0 109.517 0 130.55s5.077 40.905 13.925 58.602l42.356-32.782"
              fill="#FBBC05"
            />
            <path
              d="M130.55 50.479c24.514 0 41.05 10.589 50.479 19.438l36.844-35.974C195.245 12.91 165.798 0 130.55 0 79.49 0 35.393 29.301 13.925 71.947l42.211 32.783c10.59-31.477 39.891-54.251 74.414-54.251"
              fill="#EB4335"
            />
          </svg>
        </div>{" "}
        Login with Google
      </span>
    );

    return (
      <LoaderButton
        block
        bsSize="large"
        variant="light"
        className="GoogleButton"
        text={googleText}
        loadingText="Logging into Google"
        isLoading={this.state.isLoading}
        onClick={this.handleClick}
        disabled={this.state.isLoading}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  userProfileLoginState: state.userProfile.loginState,
});

export default connect(mapStateToProps, {
  onSignIn,
})(GoogleButton);
