import { combineReducers } from "redux";

import userProfileReducer from "./userProfileReducer";

import PopupMessagesReducer from "./PopupMessagesReducer";
import LensesAndTools from "./lensAndToolkitDatabase";

export default combineReducers({
  userProfile: userProfileReducer,
  popupMessages: PopupMessagesReducer,
  lensAndToolDatabase: LensesAndTools,
});
