import React, { Component } from "react";

export default function renderHeader(title, text) {
  return (
    <div
      className="text-center pageSubheader"
      data-aos="fade-right"
      data-aos-duration="1000"
    >
      <div style={{ display: "table" }}>
        <div
          className="frontPageHeaderSmallTableCell d-none d-md-table-cell"
          style={{
            display: "table-cell",
            verticalAlign: "middle",
            width: "20%",
            minWidth: "280px",
          }}
        >
          <h1>{title}</h1>
        </div>
        <div
          style={{
            display: "table-cell",
            padding: "0px 10px 0px 10px",
          }}
        >
          <div
            style={{
              display: "table-cell",
              borderLeft: "2px solid orange",
              padding: "0px 10px 0px 10px",
              textAlign: "left",
              marginLeft: "10px",
            }}
          >
            <h3>{text}</h3>
          </div>
        </div>
      </div>
    </div>
  );
}
