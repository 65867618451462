export const SHOW_ERROR_MESSAGE = "SHOW_ERROR_MESSAGE";
export const HIDE_ERROR_MESSAGE = "HIDE_ERROR_MESSAGE";
export const SHOW_OK_MESSAGE = "SHOW_OK_MESSAGE";
export const HIDE_OK_MESSAGE = "HIDE_OK_MESSAGE";
export const SHOW_PRIVACY_POLICY = "SHOW_PRIVACY_POLICY";
export const HIDE_PRIVACY_POLICY = "HIDE_PRIVACY_POLICY";
export const SHOW_TERMS_OF_SERVICE = "SHOW_TERMS_OF_SERVICE";
export const HIDE_TERMS_OF_SERVICE = "HIDE_TERMS_OF_SERVICE";

export function showErrorMessage(message) {
  return async function (dispatch) {
    dispatch({
      type: SHOW_ERROR_MESSAGE,
      payload: {
        message: message,
      },
    });
  };
}

export function hideErrorMessage() {
  return async function (dispatch) {
    dispatch({
      type: HIDE_ERROR_MESSAGE,
    });
  };
}

export function showOKMessage(message) {
  return async function (dispatch) {
    dispatch({
      type: SHOW_OK_MESSAGE,
      payload: {
        message: message,
      },
    });
  };
}

export function hideOKMessage() {
  return async function (dispatch) {
    dispatch({
      type: HIDE_OK_MESSAGE,
    });
  };
}

export function showPrivacyPolicy() {
  return async function (dispatch) {
    dispatch({
      type: SHOW_PRIVACY_POLICY,
    });
  };
}

export function hidePrivacyPolicy() {
  return async function (dispatch) {
    dispatch({
      type: HIDE_PRIVACY_POLICY,
    });
  };
}

export function showTermsOfService() {
  return async function (dispatch) {
    dispatch({
      type: SHOW_TERMS_OF_SERVICE,
    });
  };
}

export function hideTermsOfService() {
  return async function (dispatch) {
    dispatch({
      type: HIDE_TERMS_OF_SERVICE,
    });
  };
}
