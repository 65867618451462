import React, { Component } from "react";
import { Button } from "react-bootstrap";
import LoaderButton from "../buttons/LoaderButton";
import "./LoginPanel.css";
import { Auth } from "aws-amplify";
import FacebookButton from "./FacebookButton";
import GoogleButton from "./GoogleButton";
import { onSignIn } from "../../reduxStores/actions/userProfileActions";
import {
  showPrivacyPolicy,
  showTermsOfService,
} from "../../reduxStores/actions/PopupMessagesActions";

import config from "../../config";
import { connect } from "react-redux";
import { showErrorMessage } from "../../reduxStores/actions/PopupMessagesActions";
import { showOKMessage } from "../../reduxStores/actions/PopupMessagesActions";
import { USER_PROFILE_LOADING } from "../../reduxStores/actions/userProfileActions";
import LoadingBanner from "../../components/CommonWidgets/LoadingBanner/LoadingBanner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import NewAccountPanel from "./NewAccountPanel";
import JInput from "../CommonWidgets/JInput/JInput";

class LoginPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      phase: "signin",
      email: "",
      password: "",
      newpassword: "",
      newpassword2: "",
      facebookLogin: false,
      firstName: "",
      lastName: "",
      myemail: "",
      mypassword: "",
      mypassword2: "",
      agree: false,
    };
    //this.handleFbLogin = this.handleFbLogin.bind(this);
    this.clickBackToLogin = this.clickBackToLogin.bind(this);
  }

  async login() {
    try {
      this.setState({ loggingIn: true });
      let info = await Auth.signIn(
        this.state.email.toLowerCase(),
        this.state.password
      );

      this.setState({ loggingIn: false });
      this.props.onSignIn();

      //this.props.history.push("/main");
    } catch (e) {
      console.log("LOGIN ERROR");
      console.log(JSON.stringify(e));
      this.setState({ loggingIn: false });
      switch (e.code) {
        case "UserNotConfirmedException":
          await Auth.resendSignUp(this.state.email);
          this.setState({
            phase: "signUpCode",
          });
          break;
        case "NotAuthorizedException":
          this.props.showErrorMessage(
            "Sorry... we could not log you in.  Please check your email and password, and try again."
          );
          break;
        case "NetworkError":
          this.props.showErrorMessage(
            "Sorry... there seems to be some issues with the internet connection.  Please try again later."
          );
          break;
        default:
          this.props.showErrorMessage(
            "Sorry... there seems to be some issues with logging on.  Please check your email and password, and try again or please try again later."
          );
      }
    }
  }

  handleChange = (event) => {
    let value = event.target.value;
    if (event.target.id === "email") value = value.toLowerCase();

    this.setState({
      [event.target.id]: value,
    });
  };

  forgotPassword() {
    this.setState({
      phase: "forgotPassword",
    });
  }

  showSignUpForm() {
    this.setState({
      phase: "signUp",
    });
  }

  async sendEmailReset() {
    this.setState({
      submittingPasswordReset: true,
    });
    try {
      Auth.forgotPassword(this.state.resetPasswordEmail.toLowerCase())
        .then((data) => {
          this.setState({
            phase: "confirmationCode",
            submittingPasswordReset: false,
          });
        })
        .catch((e) => {
          this.setState({
            submittingPasswordReset: false,
          });
          this.props.showErrorMessage(e.message, false);
        });
    } catch (e) {
      // something went wrong!
      console.log(JSON.stringify(e));
    }
  }

  validatePassword() {
    const password = this.state.newpassword.trim();
    if (password.length < 8)
      return "passwords need to contain at least 8 characters";
    return "";
  }

  validatePasswordMatch() {
    const password1 = this.state.newpassword;
    const password2 = this.state.newpassword2;
    if (password1 !== password2) return "passwords must match";
    return "";
  }

  formIsValid() {
    var errors = this.validatePassword() + this.validatePasswordMatch();
    if (errors.length > 0) return false;
    return true;
  }

  async changePassword() {
    this.setState({
      submittingPasswordReset: true,
    });
    Auth.forgotPasswordSubmit(
      this.state.resetPasswordEmail,
      this.state.passwordResetCode,
      this.state.newpassword
    )
      .then((data) => {
        this.setState({
          phase: "signin",
          submittingPasswordReset: false,
        });
        this.props.showOKMessage(
          "You have successfully changed your password.  You can now login.",
          null
        );
      })
      .catch((e) => {
        this.setState({
          submittingPasswordReset: false,
        });
        this.props.showErrorMessage(e.message, false);
      });
  }

  cancelVerify() {
    this.setState({
      phase: "signin",
    });
  }

  renderFacebookLogin() {
    return (
      <div className="text-center">
        <div>- OR -</div>
        <FacebookButton onLogin={this.props.onLogin} />
        <GoogleButton onLogin={this.props.onLogin} />
      </div>
    );
  }

  clickBackToLogin() {
    this.setState({
      phase: "signin",
    });
  }

  renderBackToLogin() {
    return (
      <div className="BackToHomeSubNav" onClick={this.clickBackToLogin}>
        <FontAwesomeIcon icon="chevron-left" /> Back to login
      </div>
    );
  }

  renderLogin() {
    return (
      <div>
        <form>
          <div class="form-group">
            <label>Email:</label>
            <JInput
              type="text"
              class="form-control"
              id="email"
              value={this.state.email}
              onChange={this.handleChange}
              placeholder="email"
            />
          </div>
          <div class="form-group">
            <label>Password:</label>
            <JInput
              type="password"
              class="form-control"
              id="password"
              value={this.state.password}
              onChange={this.handleChange}
              placeholder="password"
            />
            <div class="small clearfix">
              <span class="float-right">
                <a
                  href="#"
                  class="button"
                  onClick={(e) => this.forgotPassword(e)}
                >
                  Forgot Password
                </a>
              </span>
            </div>
          </div>
          <div class="text-right">
            <LoaderButton
              className="btn-block"
              bsSize="large"
              onClick={(e) => this.login(e)}
              text="LOG IN"
              variant="success"
              loadingText="Logging In..."
              isLoading={this.state.loggingIn}
            />
          </div>
        </form>
        <div>
          Don't have an account?{" "}
          <a href="#" onClick={() => this.showSignUpForm()}>
            Sign up here
          </a>{" "}
          - it's free!
        </div>
        {this.renderFacebookLogin()}
        <div className="text-center">
          <a href="#" onClick={() => this.props.showPrivacyPolicy()}>
            Privacy Policy
          </a>{" "}
          <a href="#" onClick={() => this.props.showTermsOfService()}>
            Terms of Service
          </a>
        </div>
      </div>
    );
  }

  renderForgotPassword() {
    return (
      <form>
        {this.renderBackToLogin()}
        <h4>Forgotten Password</h4>
        <p>
          Please enter your email address, and we will send you a verification
          code to enable you to change your password.
        </p>
        <div class="form-group">
          <label>Email:</label>
          <JInput
            type="email"
            class="form-control"
            id="resetPasswordEmail"
            value={this.state.resetPasswordEmail}
            onChange={this.handleChange}
          />
        </div>
        <LoaderButton
          className="btn-success"
          onClick={(e) => this.sendEmailReset(e)}
          text="Submit"
          variant="success"
          loadingText="Submitting ..."
          isLoading={this.state.submittingPasswordReset}
        />{" "}
        <Button variant="danger" onClick={(e) => this.cancelVerify(e)}>
          Cancel
        </Button>
      </form>
    );
  }

  renderConfirmationCode() {
    const password1Error = this.validatePassword();
    const password2Error = this.validatePasswordMatch();
    const formIsValid = this.formIsValid();
    return (
      <form>
        {this.renderBackToLogin()}
        <h4>Reset Password</h4>
        <p>
          You have been sent a verification code to your email address (
          {this.state.resetPasswordEmail.toLowerCase()}). Please enter this code
          below in order to change your password:
        </p>
        <p>
          <small>
            <u>Please note:</u> If you did not receive a code, and you believe
            the email address is correct, but are not sure you have logged into
            your account before, you may need to{" "}
            <a href="#" onClick={() => this.showSignUpForm()}>
              create an account
            </a>
          </small>
        </p>
        <div class="form-group">
          <label for="passwordResetCode">Code:</label>
          <JInput
            type="text"
            class="form-control"
            id="passwordResetCode"
            value={this.state.passwordResetCode}
            onChange={this.handleChange}
          />
        </div>
        <div class="form-group">
          <label for="newpassword">Type your new password:</label>
          <JInput
            type="password"
            class="form-control"
            id="newpassword"
            value={this.state.newpassword}
            onChange={this.handleChange}
          />
          {this.state.newpassword.length > 0 && (
            <div class="text-danger">{password1Error}</div>
          )}
        </div>
        <div class="form-group">
          <label for="newpassword2">Retype your password:</label>
          <JInput
            type="password"
            class="form-control"
            id="newpassword2"
            value={this.state.newpassword2}
            onChange={this.handleChange}
          />
          {this.state.newpassword2.length > 0 && (
            <div class="text-danger">{password2Error}</div>
          )}
        </div>
        <LoaderButton
          disabled={!formIsValid}
          variant="success"
          onClick={(e) => this.changePassword(e)}
          text="Submit"
          loadingText="Submitting..."
          isLoading={this.state.submittingPasswordReset}
        />{" "}
        <Button variant="danger" onClick={(e) => this.cancelVerify(e)}>
          Cancel
        </Button>
      </form>
    );
  }

  renderSignUp() {
    return (
      <NewAccountPanel
        clickBackToLogin={this.clickBackToLogin}
        onLogin={this.props.onLogin}
        history={this.props.history}
        under13Allowed={false}
      />
    );
  }

  async verifySignUpCode() {
    this.setState({ isLoading: true });
    try {
      let info = await Auth.confirmSignUp(
        this.state.email.toLowerCase(),
        this.state.verificationCode
      );
      this.props.showOKMessage(
        "Your account has been verified ... you can now login!"
      );
      this.clickBackToLogin();
    } catch (e) {
      this.props.showErrorMessage(e.message, false);
    }
  }

  renderSignUpCode() {
    return (
      <div>
        <p>Your account has not yet been verified.</p>
        <p>
          You have been sent a verification code to your email address (
          {this.state.email.toLowerCase()}
          ). Please enter this code below:
        </p>
        <form name="verificationForm">
          <div class="form-group">
            <label for="myemail">Code:</label>
            <JInput
              type="text"
              class="form-control"
              id="verificationCode"
              value={this.state.verificationCode}
              onChange={this.handleChange}
            />
          </div>
          <LoaderButton
            className="btn-block"
            variant="success"
            onClick={(e) => this.verifySignUpCode(e)}
            text="Submit"
            loadingText="Submitting..."
            isLoading={this.state.isLoading}
          />
          <Button
            variant="danger"
            className="btn-block"
            onClick={() => this.cancelVerify()}
          >
            Cancel
          </Button>
        </form>
      </div>
    );
  }

  renderForm() {
    return (
      <div>
        {this.state.phase === "signin" && this.renderLogin()}
        {this.state.phase === "forgotPassword" && this.renderForgotPassword()}
        {this.state.phase === "confirmationCode" &&
          this.renderConfirmationCode()}
        {this.state.phase === "signUp" && this.renderSignUp()}
        {this.state.phase === "signUpCode" && this.renderSignUpCode()}
      </div>
    );
  }

  render() {
    let userLoading = this.props.userProfileLoginState === USER_PROFILE_LOADING;

    //let disabled = this.props.headerText === "Parent Login";
    let disabled = false;
    if (
      this.props.headerText === "Teacher Login" &&
      !config.currentRelease.enableClasses
    ) {
      disabled = true;
    }

    return (
      <div class="LoginPanel">
        <h3 className={this.props.headerColour}>
          <b>{this.props.headerText}</b>
        </h3>
        {disabled && (
          <p>
            Sorry... this feature has not been implemented yet. Please come back
            soon!
          </p>
        )}
        {userLoading && <LoadingBanner />}
        {!userLoading && !disabled && this.renderForm()}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  userProfileLoginState: state.userProfile.loginState,
});

export default connect(mapStateToProps, {
  showErrorMessage,
  showOKMessage,
  onSignIn,
  showPrivacyPolicy,
  showTermsOfService,
})(LoginPanel);
