import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";
import { connect } from "react-redux";
import { hideTermsOfService } from "../../reduxStores/actions/PopupMessagesActions";

class TermsOfServicePopup extends Component {
  render() {
    return (
      <div className="TermsOfServicePopup">
        <Modal
          show={this.props.termsOfServiceVisible}
          onHide={() => this.props.hideTermsOfService()}
          size="lg"
        >
          <Modal.Header closeButton>
            <div className="text-success">Terms of Service</div>
          </Modal.Header>

          <Modal.Body>
            <p>
              <b>Last updated: 28/7/2020</b>
            </p>
            <p>
              Please read these Terms and Conditions ("Terms", "Terms and
              Conditions") carefully before using the
              https://www.theagilelife.com.au website operated by New Dawn
              Technologies ("us", "we", or "our").
            </p>
            <p>
              Your access to and use of the Service is conditioned on your
              acceptance of and compliance with these Terms. These Terms apply
              to all visitors, users and others who access or use the Service.
            </p>
            <p>
              If you are a parent or guardian and you consent to your child’s
              registration with the Website, then you agree to be bound by these
              Terms and Conditions with respect to your child’s use of the
              Website.
            </p>
            <p>
              By accessing or using the Service you agree to be bound by these
              Terms. If you disagree with any part of the terms then you may not
              access the Service.
            </p>
            <p>
              <b>Registration</b>
            </p>
            <p>
              You must complete the registration details accurately. By becoming
              a Website subscriber (“Subscriber”) for any of the services
              offered by the Website, you represent and warrant that the
              information you provide is complete and accurate. You must not use
              any incomplete, false or inaccurate information for purposes of
              becoming a Subscriber. We may accept or reject your application to
              become a Subscriber for any reason whatsoever. If we determine
              that the information that you provide us is inaccurate or
              misleading in any way then we may terminate your subscription.
            </p>
            <p>
              <b>Subscriptions</b>
            </p>
            <p>
              Some parts of the Service are billed on a subscription basis
              ("Subscription(s)").
            </p>
            <p>
              We reserve the right at our exclusive discretion to vary the mix
              of free and paid features offered to users and the right to
              discontinue any free services at any time of our choosing.
            </p>
            <p>
              <b>Refund Policies</b>
            </p>
            <p>
              All new purchases are allowed an unconditional 15-day money back
              guarantee. The costs of any returns will be at your expense,
              unless otherwise provided by law.
            </p>
            <p>
              <b>Errors</b>
            </p>
            <p>
              If we make an error on your bill, we will correct it promptly
              after you tell us and we investigate the charge. You must tell us
              within 60 days after an error first appears on your bill. You
              release us from all liability and claims of loss resulting from
              any error that you do not report to us within 60 days after the
              error first appears on your online statement. If you do not tell
              us within this time, we will not be required to correct the error.
              We can correct billing errors at any time.
            </p>
            <p>
              <b>Our Services</b>
            </p>
            <p>
              We cannot be responsible for any delays or interruptions to the
              Website. We will use commercially reasonable efforts to minimise
              delays and interruptions. However, we cannot warrant that the
              Website will be available at all times or at any given time.
            </p>
            <p>
              We may at any time and without notice to you, discontinue the
              Website in whole or in part. However, we cannot be responsible for
              any loss, cost, damage or liability that may result from our
              discontinuance of the Website.
            </p>
            <p>
              <b>We Make No Warranty</b>
            </p>
            <p>
              We provide the Service "as-is," "with all faults" and "as
              available." New Dawn Technologies gives no express warranties,
              guarantees or conditions. You may have additional consumer rights
              under your local laws that this contract cannot change.
            </p>
            <p>
              <b>Links To Other Web Sites</b>
            </p>
            <p>
              Our Service may contain links to third-party web sites or services
              that are not owned or controlled by New Dawn Technologies.
            </p>
            <p>
              New Dawn Technologies has no control over, and assumes no
              responsibility for, the content, privacy policies, or practices of
              any third party web sites or services. You further acknowledge and
              agree that New Dawn Technologies shall not be responsible or
              liable, directly or indirectly, for any damage or loss caused or
              alleged to be caused by or in connection with use of or reliance
              on any such content, goods or services available on or through any
              such web sites or services.
            </p>
            <p>
              <b>Changes</b>
            </p>
            <p>
              We reserve the right, at our sole discretion, to modify or replace
              these Terms at any time. If a revision is material we will try to
              provide at least 30 days' notice prior to any new terms taking
              effect. What constitutes a material change will be determined at
              our sole discretion.
            </p>
            <p>
              <b>Contact Us</b>
            </p>
            <p>
              If you have any questions about these Terms, please contact us.
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="success"
              onClick={() => this.props.hideTermsOfService()}
            >
              OK
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  termsOfServiceVisible: state.popupMessages.termsOfServiceVisible,
});

export default connect(mapStateToProps, {
  hideTermsOfService,
})(TermsOfServicePopup);
