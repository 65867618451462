import React, { Component } from "react";
import "./keywordSearch.css";
import SearchEngine from "./SearchEngine";

class Keyword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      deleteHover: false,
    };
  }
  render() {
    let className = this.state.deleteHover
      ? "keyword highlightedToDelete"
      : "keyword";
    return (
      <div className={className}>
        {this.props.keyword}{" "}
        <div
          className="delete"
          onClick={() => this.props.onDelete()}
          onMouseEnter={() => this.setState({ deleteHover: true })}
          onMouseLeave={() => this.setState({ deleteHover: false })}
        >
          x
        </div>
      </div>
    );
  }
}

export default class KeywordSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      keywordSearchTerm: "",
      hasFocus: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.searchEngine = new SearchEngine(props.library, this.props.keywords);
  }

  deleteKeyword(k) {
    let newList = [...this.props.keywords];
    let keywordPos = newList.indexOf(k);
    newList.splice(keywordPos, 1);
    this.searchEngine = new SearchEngine(this.props.library, newList);
    this.props.onChange(newList);
  }

  renderKeywords() {
    return (
      <div className="keywordArea">
        {this.props.keywords.map((k) => (
          <Keyword keyword={k} onDelete={() => this.deleteKeyword(k)} />
        ))}
      </div>
    );
  }

  handleChange(event) {
    let value = event.target.value;
    this.setState({
      keywordSearchTerm: value,
    });
  }

  addTerm(keyword) {
    if (!keyword) keyword = this.state.keywordSearchTerm;
    this.setState({
      keywordSearchTerm: "",
    });
    let newList = [...this.props.keywords];

    newList.push(keyword);
    this.searchEngine = new SearchEngine(this.props.library, newList);
    this.props.onChange(newList);
  }

  onInputFocus() {
    this.setState({ hasFocus: true });
    if (this._blurTimer) {
      clearTimeout(this._blurTimer);
      delete this._blurTimer;
    }
  }

  onInputBlur() {
    let _this = this;

    let timeout = setTimeout(function () {
      _this.setState({ hasFocus: false });
      delete _this._blurTimer;
    }, 200);
    this._blurTimer = timeout;
  }

  renderAddTerm() {
    let searchList = this.searchEngine.generateSearchResults(
      this.state.keywordSearchTerm
    );

    return (
      <div
        className="searchArea"
        onFocus={() => this.onInputFocus()}
        onBlur={() => this.onInputBlur()}
      >
        <div>
          <input
            id="keywordSearchTerm"
            value={this.state.keywordSearchTerm}
            onChange={this.handleChange}
            autocomplete="off"
          />
          <button onClick={() => this.addTerm()}>add keyword</button>
        </div>

        <div className="searchResults">
          {this.state.hasFocus &&
            searchList.map((item) => (
              <div
                className="searchResultItem"
                onClick={() => this.addTerm(item.term)}
              >
                {item.term + " (" + item.count + ")"}
              </div>
            ))}
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="keywordSearch">
        {this.renderKeywords()}
        {this.renderAddTerm()}
      </div>
    );
  }
}
