import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";

export default class ConfirmMessagePopup extends Component {
  render() {
    return (
      <div className="OkMessagePopup">
        <Modal show={true} onHide={this.props.onClose} size="lg">
          <Modal.Header closeButton>
            <div className="text-success">{this.props.heading}</div>
          </Modal.Header>

          <Modal.Body>{this.props.message}</Modal.Body>
          <Modal.Footer>
            <Button variant="success" onClick={this.props.onOK}>
              OK
            </Button>
            <span> </span>
            <Button variant="danger" onClick={this.props.onClose}>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}
