import React from "react";

const Select = ({ name, label, error, options, ...rest }) => {
  const optionsWithNull = [{ label: "", value: "" }, ...options];

  return (
    <div className="form-group">
      <label htmlFor={name}>{label}:</label>
      <select {...rest} className="form-control" id={name} name={name}>
        {optionsWithNull.map((option) => (
          <option value={option.value}>{option.label}</option>
        ))}
      </select>

      {error && <div className="alert alert-danger">{error}</div>}
    </div>
  );
};

export default Select;
