import store from "../../reduxStores/store";

export default class SearchEngine {
  constructor(library, currentKeywords) {
    this.generateSearchObjects(library, currentKeywords);
  }

  getMatchingKeywordCount(keyWordList, currentKeywords) {
    let count = 0;
    for (var i = 0; i < keyWordList.length; i++) {
      let kw = keyWordList[i];
      if (currentKeywords.indexOf(kw) >= 0) count++;
    }
    return count;
  }

  getUnmatchingKeywords(keyWordList, currentKeywords) {
    let list = [];
    for (var i = 0; i < keyWordList.length; i++) {
      let kw = keyWordList[i];
      if (currentKeywords.indexOf(kw) < 0) list.push(kw);
    }
    return list;
  }

  generateSearchObjects(library, currentKeywords) {
    let state = store.getState();
    let obs = state.lensAndToolDatabase[library];

    let searchableTermData = {};

    for (var i in obs) {
      let keyWordList = obs[i].keywords;
      let evidence = this.getMatchingKeywordCount(keyWordList, currentKeywords);
      let searchableTerms = this.getUnmatchingKeywords(
        keyWordList,
        currentKeywords
      );
      for (var j = 0; j < searchableTerms.length; j++) {
        let term = searchableTerms[j];
        if (!searchableTermData[term]) {
          searchableTermData[term] = {
            evidence: 0,
            count: 0,
          };
        }
        let data = searchableTermData[term];
        data.evidence += evidence;
        data.count++;
      }
    }
    this.searchTermData = searchableTermData;
  }

  generateSearchResults(searchString) {
    let matchingTerms = [];
    for (var t in this.searchTermData) {
      let data = this.searchTermData[t];
      let match = data.evidence + data.count * 0.1;
      matchingTerms.push({
        term: t,
        count: data.count,
        strength: match,
      });
    }

    // lets sort, and then just return the strings
    matchingTerms.sort(function (a, b) {
      return b.strength - a.strength;
    });

    return matchingTerms;
  }
}
