import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";
import { connect } from "react-redux";
import { hidePrivacyPolicy } from "../../reduxStores/actions/PopupMessagesActions";

class PrivacyPolicyPopup extends Component {
  render() {
    return (
      <div className="PrivacyPolicyPopup">
        <Modal
          show={this.props.privacyPolicyVisible}
          onHide={() => this.props.hidePrivacyPolicy()}
          size="lg"
        >
          <Modal.Header closeButton>
            <div className="text-success">Privacy Policy</div>
          </Modal.Header>

          <Modal.Body>
            <p>
              <b>Last updated: 28/7/2020</b>
            </p>
            <p>
              New Dawn Technologies ("us", "we", or "our") operates
              https://www.theagilelife.com.au (the "Site"). This page informs
              you of our policies regarding the collection, use and disclosure
              of Personal Information we receive from users of the Site.
            </p>
            <p>
              We are committed to protecting you and your family’s privacy when
              you visit the Site. By using the Site, you agree to the collection
              and use of information in accordance with this policy.
            </p>
            <p>
              <b>Information Collection And Use</b>
            </p>
            <p>
              While using our Site, we may ask you to provide us with certain
              personally identifiable information that can be used to contact or
              identify you. Personally identifiable information may include, but
              is not limited to your name and email address. We only ask site
              users to submit the minimum amount of information necessary to use
              our service. We never share any information submitted to our
              website to third parties for their marketing purposes.
            </p>
            <p>
              <b>Collection of Information from Children</b>
            </p>
            <p>
              In compliance with COPPA (the Children’s Online Privacy Protection
              act), we have adopted the following procedures to keep kids safe:
            </p>
            <ul>
              <li>
                We do not collect information that allows us to identify
                children individually.
              </li>
              <li>
                We do not communicate personally with children by email nor does
                the site allow children using the system to communicate with us
                by email. The system may send out automated reminder emails, to
                all users, but is non-personal in nature.
              </li>
            </ul>
            <p>
              If you are under the age of 13, do not provide personal
              information to this or any site, or within the software
              registration process, without your parents’ permission.
            </p>
            <p>
              <b>Log Data</b>
            </p>
            <p>
              Like many site operators, we collect information that your browser
              sends whenever you visit our Site ("Log Data").
            </p>
            <p>
              This Log Data may include information such as your computer's
              Internet Protocol ("IP") address, browser type, browser version,
              the pages of our Site that you visit, the time and date of your
              visit, the time spent on those pages and other statistics.
            </p>
            <p>
              In addition, we may use third party services such as Google
              Analytics that collect, monitor and analyze this information.
            </p>
            <p>
              <b>Communications</b>
            </p>
            <p>
              We may use your Personal Information to contact you with reminder
              emails, newsletters, marketing or promotional materials.
            </p>
            <p>
              <b>Cookies</b>
            </p>
            <p>
              Cookies are files with small amount of data, which may include an
              anonymous unique identifier. Cookies are sent to your browser from
              a web site and stored on your computer's hard drive.
            </p>
            <p>
              Like many sites, we use "cookies" to collect information. You can
              instruct your browser to refuse all cookies or to indicate when a
              cookie is being sent. However, if you do not accept cookies, you
              may not be able to use some portions of our Site.
            </p>
            <p>
              <b>Security</b>
            </p>
            <p>
              The security of your Personal Information is important to us, but
              remember that no method of transmission over the Internet, or
              method of electronic storage, is 100% secure. While we strive to
              use commercially acceptable means to protect your Personal
              Information, we cannot guarantee its absolute security.
            </p>
            <p>
              <b>Changes To This Privacy Policy</b>
            </p>
            <p>
              This Privacy Policy is effective as of 28/7/2020 and will remain
              in effect except with respect to any changes in its provisions in
              the future, which will be in effect immediately after being posted
              on this page.
            </p>
            <p>
              We reserve the right to update or change our Privacy Policy at any
              time and you should check this Privacy Policy periodically. Your
              continued use of the Service after we post any modifications to
              the Privacy Policy on this page will constitute your
              acknowledgment of the modifications and your consent to abide and
              be bound by the modified Privacy Policy.
            </p>
            <p>
              If we make any material changes to this Privacy Policy, we will
              notify you either through the email address you have provided us,
              or by placing a prominent notice on our website.
            </p>
            <p>
              <b>Contact Us</b>
            </p>
            <p>
              If you have any questions about this Privacy Policy, please
              contact us.
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="success"
              onClick={() => this.props.hidePrivacyPolicy()}
            >
              OK
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  privacyPolicyVisible: state.popupMessages.privacyPolicyVisible,
});

export default connect(mapStateToProps, {
  hidePrivacyPolicy,
})(PrivacyPolicyPopup);
