import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import titleHeader from "../components/headers/titleHeader";
import renderFooter from "../components/headers/footer";

export default function Services(props) {
  function renderHeader() {
    return titleHeader(
      "What we do",
      "We help you tackle challenging business, project and leadership problems through design thinking, agile methods and the latest evidence-based practices utilised by the world's leading organisations."
    );
  }

  function renderContactButtons() {
    return (
      <Container>
        <Row>
          <Col md={6}>
            <div
              className="lowerButton"
              onClick={() => props.onSelect("#about")}
            >
              Who Are We?
            </div>
          </Col>
          <Col md={6}>
            <div
              className="lowerButton"
              onClick={() => props.onSelect("#contact")}
            >
              Contact Us
            </div>
          </Col>
        </Row>
      </Container>
    );
  }

  function renderWhatServices() {
    return (
      <div style={{ borderTop: "3px solid white" }}>
        <div
          className="blackOnWhiteText paddedTopAndBottom"
          style={{
            overflowX: "hidden",
            overflowY: "hidden",
            marginTop: "15px",
          }}
        >
          <Container>
            <Row>
              <Col>
                <div data-aos="fade-up" data-aos-duration="1000">
                  <h4>What We Do</h4>
                  <p>
                    In a volatile and uncertain world, it is easy to feel
                    disoriented, and struggling to keep afloat.
                  </p>
                  <p>
                    We help you quickly find your direction, and better ride the
                    waves of opportunity that can take you where you truly wish
                    to head.
                  </p>
                  <p>
                    We provide workshops, seminars, and facilitated
                    creative-problem solving sessions, as well as group and
                    individual coaching in a number of areas including:
                  </p>
                </div>
              </Col>
            </Row>

            <Row>
              <Col>
                <Container className="examplesBox">
                  <Row data-aos="fade-right" data-aos-duration="1000">
                    <Col>
                      <ul>
                        <li>enterprise and transformational leadership</li>
                        <li>high performance teamwork</li>
                        <li>
                          workforce resilience, motivation, and creativity
                        </li>
                        <li>emotional intelligence</li>
                        <li>workplace conflict, culture and change</li>
                        <li>the neuroscience of high performance</li>
                      </ul>
                    </Col>
                    <Col>
                      <ul>
                        <li>strategy development and implementation</li>
                        <li>business model development and refinement</li>
                        <li>operating model development and refinement</li>
                        <li>project and portfolio management</li>
                        <li>customer experience</li>
                        <li>strategy led IT planning</li>
                      </ul>
                    </Col>
                  </Row>
                </Container>
              </Col>
            </Row>
            <Row>
              <Col>
                <div>
                  <h5>A Co-Created, Personalised Approach</h5>

                  <p>
                    We co-create solutions with you , drawing on a wide range of
                    approaches. From design thinking and agile methodologies
                    through to the latest evidence-based approaches taught at
                    the world’s leading organisations and business schools.
                  </p>

                  <p>
                    <span
                      className="hyperlink highlighted"
                      onClick={() => props.onSelect("#contact")}
                    >
                      Contact us
                    </span>{" "}
                    for a free consultation to explore how we may be able to
                    assist you and your organisation.
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
          <div style={{ borderTop: "3px solid white" }}>
            <div className="image-and-content blueBackground">
              <div className="image-and-content__image">
                <img src="images/happyteam5.jpg" />
              </div>
              <div className="image-and-content__container paddedEdges">
                <div className="image-and-content__content2">
                  <div data-aos="fade-up" data-aos-duration="1000">
                    <h4>Why "Design" Your Business Solutions?</h4>
                    <p>
                      Both design thinking and agile methodologies are sets of
                      tools for solving ill-defined problems creatively .
                    </p>
                    <p>
                      These methodolologies are essential for dealing with
                      complex problems - problems for which there is too much
                      uncertainty, or not enough information to “think through”
                      a solution given the data at hand.
                    </p>
                    <p>
                      And in business, most problems are complex , ranging from
                      motivating staff through to dealing with conflict, or
                      iterating a business model - you often do not know what
                      will work until you try.
                    </p>
                    <p>
                      Instead, a degree of experimentation is required to
                      explore what may and may not work in practice.
                    </p>
                    <p>
                      Design thinking and agile provide a framework for
                      exploring solutions creatively , but in a safe-to-fail
                      manner, through iterative prototyping, feedback and
                      refinement .
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div style={{ borderTop: "3px solid white" }}>
            <div className="blackOnWhiteText">
              <div className="image-and-content__container paddedEdges paddedTopAndBottom">
                <div className="blackBox">
                  <div className="orangeBox2">
                    <div className="orangeBox__image">
                      <img src="images/creativeteam.jpg" />
                    </div>
                    <div className="orangeBox__content">
                      <div data-aos="fade-right" data-aos-duration="1000">
                        <h4>Why Us?</h4>
                        <p>
                          Dealing with complex challenges is like navigating
                          uncharted territory. You need practical strategies for
                          exploring and mapping as you move forward, but you
                          also need a North Star to make sure you are heading in
                          the right direction.
                        </p>
                        <p>
                          Our methods will help you rapidly map and explore,
                          while our deep understanding of best practice models
                          help you pick out that North Star in an otherwise
                          crowded sky.
                        </p>
                        <p>
                          Our point of difference is that we not only provide
                          the frameworks for effective creative problem-solving,
                          but we also draw on the latest business, leadership,
                          psychological and neuroscientific models that will
                          help you better define your problems and guide your
                          way forward.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {renderContactButtons()}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="frontPageContent">
      {renderHeader()}
      {renderWhatServices()}
      {renderFooter()}
    </div>
  );
}
