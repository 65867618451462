import React, { Component } from "react";
import { Container, Tabs, Tab, Button, Row, Col } from "react-bootstrap";
import KeywordSearch from "../components/search/keywordSearch";
import SortableTree from "react-sortable-tree";
import { changeNodeAtPath } from "react-sortable-tree";
import "./editor.css";

class LensEditor extends Component {
  constructor(props) {
    super(props);
    let content = JSON.parse(JSON.stringify(this.props.currentLens));
    if (!content.filterCharacteristics) {
      content.filterCharacteristics = [
        {
          title: "characteristic 1",
          shortDescription: "",
          detailedDescription: "",
          _id: 1,

          children: [
            {
              title: "subfeature 1",
              shortDescription: "",
              detailedDescription: "",
              _id: 2,
            },
          ],
        },
        {
          title: "characteristic  2",
          shortDescription: "",
          detailedDescription: "",
          _id: 3,
          children: [
            {
              title: "subfeature 2",
              shortDescription: "",
              detailedDescription: "",
              _id: 4,
            },
          ],
        },
      ];
    }

    this.state = {
      content: content,
      selectedCharacteristic: null,
    };
  }

  handleChange = (event) => {
    let value = event.target.value;
    let content = {
      ...this.state.content,
      [event.target.id]: value,
    };

    this.setState({
      content: content,
    });
  };

  updateKeywords(newList) {
    let content = {
      ...this.state.content,
      keywords: newList,
    };

    this.setState({
      content: content,
    });
  }

  renderAbout() {
    return (
      <div>
        <div className="section">
          <h4>Lens Name:</h4>
          <input
            className="fullWidth"
            id="name"
            value={this.state.content.name}
            onChange={this.handleChange}
          />
        </div>
        <div className="section">
          <h4>Lens Short Description:</h4>
          <textarea
            className="fullWidth"
            id="shortDescription"
            value={this.state.content.shortDescription}
            onChange={this.handleChange}
          />
        </div>
        <div className="section">
          <h4>When to Use:</h4>
          <textarea
            className="fullWidth"
            id="whenToUse"
            value={this.state.content.whenToUse}
            onChange={this.handleChange}
          />
        </div>
        <div className="section">
          <h4>Keywords:</h4>
          <KeywordSearch
            keywords={this.state.content.keywords}
            onChange={(newList) => this.updateKeywords(newList)}
            library="allLenses"
          />
        </div>
        <div className="section">
          <h4>References:</h4>
        </div>
      </div>
    );
  }

  onSelectFilterNode(event, rowInfo) {
    if (
      event.target.className.includes("collapseButton") ||
      event.target.className.includes("expandButton")
    ) {
      // ignore close/expand events
    } else {
      this.setState({ selectedCharacteristic: rowInfo.node });

      const treeData = changeNodeAtPath({
        treeData: this.state.content.filterCharacteristics,
        path: rowInfo.path,
        newNode: { ...rowInfo.node },
        getNodeKey: ({ node }) => node._id,
        ignoreCollapsed: true,
      });
      let content = { ...this.state.content, filterCharacteristics: treeData };
      this.setState({ content: content, selectedNodeId: rowInfo.node._id });
      //this.showForm(rowInfo.node);
    }
  }

  updateCharacteristics(tree) {
    let content = { ...this.state.content, filterCharacteristics: tree };
    this.setState({ content: content });
  }

  getNextFilterId() {
    let max = 0;
    function getMaxId(children) {
      for (var c = 0; c < children.length; c++) {
        let child = children[c];
        max = Math.max(child._id, max);
        if (child.children) getMaxId(child.children);
      }
    }

    let tree = this.state.content.filterCharacteristics;
    getMaxId(tree);
    return max + 1;
  }

  addNewMainCharacteristic() {
    let filterCharacteristics = this.state.content.filterCharacteristics;
    let nextId = this.getNextFilterId();
    filterCharacteristics.push({
      title: "new characteristic",
      _id: this.getNextFilterId(),
    });
    this.setState({
      contents: {
        ...this.state.contents,
        filterCharacteristics: filterCharacteristics,
      },
    });
  }

  handleSelectedCharacteristicChange = (event) => {
    let value = event.target.value;
    let selectedCharacteristic = {
      ...this.state.selectedCharacteristic,
      [event.target.id]: value,
    };

    this.setState({
      selectedCharacteristic: selectedCharacteristic,
    });

    let selectedNodeId = this.state.selectedNodeId;

    function updateTree(array, key, value) {
      let newArray = [];
      for (let i = 0; i < array.length; i++) {
        let item = { ...array[i] };
        if (item._id === selectedNodeId) {
          item[key] = value;
        }
        if (item.children)
          item.children = updateTree(item.children, key, value);
        newArray.push(item);
      }
      return newArray;
    }

    // now lets update the value in the content
    let content = this.state.content;
    let updatedTree = updateTree(
      content.filterCharacteristics,
      [event.target.id],
      value
    );
    content = { ...content, filterCharacteristics: updatedTree };
    this.setState({
      content: content,
    });
  };

  renderSelectedCharacteristic() {
    if (!this.state.selectedCharacteristic) return;
    return (
      <div>
        <h3>Selected Characteristic</h3>
        <div className="section">
          <h4>Characteristic Name:</h4>
          <input
            className="fullWidth"
            id="title"
            value={this.state.selectedCharacteristic.title}
            onChange={this.handleSelectedCharacteristicChange}
          />
        </div>
        <div className="section">
          <h4>Characteristic Short Description:</h4>
          <textarea
            className="fullWidth"
            id="shortDescription"
            value={this.state.selectedCharacteristic.shortDescription}
            onChange={this.handleSelectedCharacteristicChange}
          />
        </div>
        <div className="section">
          <h4>Characteristic Detailed Description:</h4>
          <textarea
            className="fullWidth"
            id="detailedDescription"
            value={this.state.selectedCharacteristic.detailedDescription}
            onChange={this.handleSelectedCharacteristicChange}
          />
        </div>
      </div>
    );
  }

  renderFilterDescription() {
    return (
      <div>
        <h2>Filter Characteristics</h2>
        <p>
          Use the below interface to describe the characteristics of this filter
          (i.e. what features does "good" have)
        </p>

        <Row>
          <Col>
            <button onClick={() => this.addNewMainCharacteristic()}>
              Create New Characteristic
            </button>
            <div style={{ height: "400px", minHeight: "400px" }}>
              <SortableTree
                treeData={this.state.content.filterCharacteristics}
                onChange={(treeData) => this.updateCharacteristics(treeData)}
                maxDepth={2}
                getNodeKey={({ node }) => node._id}
                generateNodeProps={(rowInfo) => {
                  let nodeProps = {
                    onClick: (event) => this.onSelectFilterNode(event, rowInfo),
                  };
                  if (this.state.selectedNodeId === rowInfo.node._id) {
                    nodeProps.className = "selected-node";
                  }
                  return nodeProps;
                }}
              />
            </div>
          </Col>

          <Col>{this.renderSelectedCharacteristic()}</Col>
        </Row>
      </div>
    );
  }

  renderContent() {
    return (
      <Container>
        <Tabs defaultActiveKey="about" id="lensEditor">
          <Tab eventKey="about" title="About" className="whitePanel">
            {this.renderAbout()}
          </Tab>
          <Tab eventKey="filter" title="Characteristics" className="whitePanel">
            {this.renderFilterDescription()}
          </Tab>
          <Tab eventKey="example" title="Example" className="whitePanel">
            <p>this is a description of an example</p>
          </Tab>
          <Tab
            eventKey="limitations"
            title="Limitations"
            className="whitePanel"
          >
            <p>Any limitations you can think of</p>
          </Tab>
        </Tabs>
      </Container>
    );
  }

  render() {
    return (
      <div className="GenericPopup">
        <div className="card GenericPopupInner">
          <div className="header bg-dark">
            <div className="d-inline-block title">
              <span className="text-light">Lens Editor</span>{" "}
            </div>
            <div className="float-right">
              <button
                className="btn btn-success"
                onClick={() => this.props.onSave(this.state.content)}
              >
                Save and Close
              </button>{" "}
              <button
                className="btn btn-danger"
                onClick={() => this.props.onClose()}
              >
                Close
              </button>
            </div>
          </div>
          <div className="bodyArea">{this.renderContent()}</div>
        </div>
      </div>
    );
  }
}

export default LensEditor;
