/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getAgileLifeUser = /* GraphQL */ `
  query GetAgileLifeUser($id: ID!) {
    getAgileLifeUser(id: $id) {
      id
      accountProvider
      email
      firstName
      lastName
      profileImage
    }
  }
`;
export const listAgileLifeUsers = /* GraphQL */ `
  query ListAgileLifeUsers(
    $filter: ModelagileLifeUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAgileLifeUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        accountProvider
        email
        firstName
        lastName
        profileImage
      }
      nextToken
    }
  }
`;
export const getLens = /* GraphQL */ `
  query GetLens($id: ID!) {
    getLens(id: $id) {
      id
      name
      shortDescription
      whenToUse
      keywords
      filterCharacteristics
    }
  }
`;
export const listLenss = /* GraphQL */ `
  query ListLenss(
    $filter: ModellensFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLenss(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        shortDescription
        whenToUse
        keywords
        filterCharacteristics
      }
      nextToken
    }
  }
`;
export const getTool = /* GraphQL */ `
  query GetTool($id: ID!) {
    getTool(id: $id) {
      id
      name
      shortDescription
      whenToUse
      keywords
    }
  }
`;
export const listTools = /* GraphQL */ `
  query ListTools(
    $filter: ModeltoolFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTools(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        shortDescription
        whenToUse
        keywords
      }
      nextToken
    }
  }
`;
