import React, { Component, useState } from "react";
import { Container, Row, Button } from "react-bootstrap";
import titleHeader from "../../components/headers/titleHeader";
import renderFooter from "../../components/headers/footer";
import ReactPlayer from "react-player";
import ReactGA from "react-ga";
import "./webinar.css";

const Webinar = () => {
  const [watched, setWatched] = useState(false);
  const [watched10, setWatched10] = useState(false);

  function renderHeader() {
    return titleHeader(
      "Webinar",
      "Watch our free 30 minute webinar 'Succeeding Scientificially', and receive a free gift valued at $497"
    );
  }

  const handleProgress = (p) => {
    if (p.played > 0.95 && !watched) {
      setWatched(true);
      ReactGA.event({
        category: "WebinarUser",
        action: "Viewed Webinar to end",
      });
    }
    if (p.played > 0.1 && !watched10) {
      setWatched10(true);
      ReactGA.event({
        category: "WebinarUser",
        action: "Viewed Webinar start",
      });
    }
  };

  const bookFreeSession = () => {
    ReactGA.event({
      category: "WebinarUser",
      action: "Clicked Book Appointment",
    });
    window.location.href = "https://the-agile-life.appointlet.com";
  };

  const renderWatchUntilEndPromo = () => {
    return (
      <div className="specialOfferDiv">
        <p>
          Watch the video until the end to receive a <b>free gift</b> valued at
          $497.
        </p>
      </div>
    );
  };

  const renderBookNow = () => {
    return (
      <div className="specialOfferDiv">
        <p>
          <Button
            variant="success"
            className="bookNow"
            style={{ padding: "20px" }}
            onClick={bookFreeSession}
          >
            Book your free session
          </Button>
        </p>
        <p className="mediumText">
          Book your free 1 hour coaching session above, valued at $497 if you
          are serious about better achieving your aspirational vision for the
          future, or better navigating your current challenges.
        </p>
        <p>Very limited places available, so BOOK NOW.</p>
      </div>
    );
  };

  return (
    <div>
      {renderHeader()}
      <div style={{ borderTop: "3px solid white" }}>
        <div
          className="blackOnWhiteText paddedTopAndBottom"
          style={{
            overflowX: "hidden",
            overflowY: "hidden",
            marginTop: "15px",
          }}
        >
          <Container>
            <div style={{ width: "100%" }}>
              <h1 className="text-center">Succeeding Scientificially</h1>
              <h3 className="text-center">
                3 breakthrough strategies for effectively accomplishing your
                goals
              </h3>
            </div>

            <div
              style={{
                width: "100%",
                maxWidth: "800px",
                display: "block",
                marginLeft: "auto",
                marginRight: "auto",
              }}
            >
              <div
                style={{
                  width: "100%",
                  paddingTop: "56.25%",
                  position: "relative",
                }}
              >
                <ReactPlayer
                  className="react-player"
                  //url="https://iknowimages.s3.amazonaws.com/agilelifevideos/final_3.mp4"
                  url="https://youtu.be/pufrrI7nsZE"
                  width="100%"
                  height="100%"
                  controls={true}
                  style={{
                    position: "absolute",
                    top: 0,
                    bottom: 0,
                    left: 0,
                    right: 0,
                  }}
                  onProgress={handleProgress}
                />
              </div>
            </div>
            {watched && renderBookNow()}
            {!watched && renderWatchUntilEndPromo()}
          </Container>
        </div>
      </div>
      {renderFooter()}
    </div>
  );
};

export default Webinar;
