import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import titleHeader from "../../components/headers/titleHeader";
import renderFooter from "../../components/headers/footer";
import ReactGA from "react-ga";

export default function Thanks() {
  function renderHeader() {
    return titleHeader("Thanks", "We have booked you in!");
  }

  return (
    <div>
      {renderHeader()}
      <div style={{ borderTop: "3px solid white" }}>
        <div
          className="blackOnWhiteText paddedTopAndBottom"
          style={{
            overflowX: "hidden",
            overflowY: "hidden",
            marginTop: "15px",
          }}
        >
          <Container>
            <h1>Congratulations</h1>
            <p>
              If you have entered your email successfully, you will soon receive
              a confirmation email, with a calendar invite.
            </p>
            <p>
              The invitation will contain a link that you can use on the day for
              your free consultation, that will start the zoom session.
            </p>
            <p>We very much look forward to speaking with you!</p>
            <p>
              If you do not receive this message, please check your spam folder,
              or you can email us directly at{" "}
              <a href="mailto:enquiries@theagilelife.com.au">
                enquiries@theagilelife.com.au
              </a>
              .
            </p>
            <p>Chat soon!</p>
            <p>Scott and David</p>
          </Container>
        </div>
      </div>
      {renderFooter()}
    </div>
  );
}
