import React, { Component } from "react";
import { Container, Tabs, Tab } from "react-bootstrap";
import "./ItemList.css";

class ItemList extends Component {
  renderItem(item) {
    let image = this.props.defaultImage.resourceId;

    return (
      <div
        className="Item"
        key={item.id}
        onClick={() => this.props.onSelect(item)}
      >
        <div className="ItemIconColumn">
          <img src={image} />
        </div>
        <div className="ItemDescriptionColumn">
          <h4>{item.name}</h4>
          <table>
            <tr>
              <td>
                <label>Description</label>
              </td>
              <td>{item.shortDescription}</td>
            </tr>
            <tr>
              <td>
                <label>When to Use</label>
              </td>
              <td>{item.whenToUse}</td>
            </tr>
            <tr>
              <td>
                <label>Keywords</label>
              </td>
              <td>
                {item.keywords.map((k) => (
                  <div className="keyword">{k}</div>
                ))}
              </td>
            </tr>
          </table>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="ItemList">
        {this.props.items.map((item) => this.renderItem(item))}
      </div>
    );
  }
}

export default ItemList;
