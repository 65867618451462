import {
  SHOW_ERROR_MESSAGE,
  HIDE_ERROR_MESSAGE,
  SHOW_OK_MESSAGE,
  HIDE_OK_MESSAGE,
  SHOW_PRIVACY_POLICY,
  HIDE_PRIVACY_POLICY,
  SHOW_TERMS_OF_SERVICE,
  HIDE_TERMS_OF_SERVICE,
} from "../actions/PopupMessagesActions";

const initialState = {
  errorMessage: null,
  okMessage: null,
  termsOfServiceVisible: false,
  privacyPolicyVisible: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SHOW_ERROR_MESSAGE:
      return {
        ...state,
        errorMessage: action.payload.message,
      };
    case HIDE_ERROR_MESSAGE:
      return {
        ...state,
        errorMessage: null,
      };
    case SHOW_OK_MESSAGE:
      return {
        ...state,
        okMessage: action.payload.message,
      };
    case HIDE_OK_MESSAGE:
      return {
        ...state,
        okMessage: null,
      };
    case SHOW_PRIVACY_POLICY:
      return {
        ...state,
        privacyPolicyVisible: true,
      };
    case HIDE_PRIVACY_POLICY:
      return {
        ...state,
        privacyPolicyVisible: false,
      };
    case SHOW_TERMS_OF_SERVICE:
      return {
        ...state,
        termsOfServiceVisible: true,
      };
    case HIDE_TERMS_OF_SERVICE:
      return {
        ...state,
        termsOfServiceVisible: false,
      };
    default:
      return state;
  }
}
