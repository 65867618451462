import React from "react";
import { API, Auth, graphqlOperation } from "aws-amplify";
import * as queries from "../../graphql/queries";
import * as mutations from "../../graphql/mutations";
import store from "../store";

export const USER_STATE_UNKNOWN = "USER_STATE_UNKNOWN";
export const USER_LOGGED_IN = "USER_LOGGED_IN";
export const USER_LOGGED_OUT = "USER_LOGGED_OUT";
export const USER_PROFILE_LOADING = "USER_PROFILE_LOADING";
export const USER_PROFILE_LOADED = "USER_PROFILE_LOADED";
export const USER_PROFILE_UPDATE = "USER_PROFILE_UPDATE";

// HELPER FUNCTIONS
class UserProfileHelper {
  constructor(store) {
    this.store = store;
    this.initialised = false;
    this.userInfo = null;
  }

  setHistory(history) {
    this.history = history;
  }

  async parseUserAttributes() {
    let userData = {};
    let current = await Auth.currentAuthenticatedUser();
    let user = (await Auth.currentUserInfo()) || current;

    if (!user.attributes) user.attributes = {};

    userData.id = user.id;
    userData.email = user.email || user.attributes.email;
    userData.firstName = user.given_name || user.attributes.given_name || "?";
    userData.lastName = user.family_name || user.attributes.family_name || "?";
    userData.profileImage = user.profileImage;
    if (!userData.profileImage) {
      delete userData.profileImage;
    } else {
      userData.profileImage = JSON.stringify({
        width: 50,
        height: 50,
        resourceId: userData.profileImage,
      });
    }

    let state = this.store.getState();
    userData.accountProvider = state.userProfile.accountProvider;

    this.userInfo = userData;
  }

  async init() {
    if (this.initialised) return;
    this.initialised = true;

    // step 1
    // dispatch "loading user"
    this.store.dispatch({
      type: USER_PROFILE_LOADING,
    });

    // step 2
    // await and load user
    await this.parseUserAttributes();

    await this.reload();

    let state = this.store.getState();
    this.store.dispatch({
      type: USER_LOGGED_IN,
    });
  }

  destroy() {
    if (!this.initialised) return;
    this.initialised = false;
  }

  async createNewProfile(userData) {
    // this is if a user is logging in with a federated identity for the first time!

    //let userData = this.userInfo;

    try {
      await API.graphql(
        graphqlOperation(mutations.createAgileLifeUser, {
          input: stringify(userData),
        })
      );
    } catch (e) {
      console.log(e);
      alert("failed creating User " + JSON.stringify(e));
    }
    this.store.dispatch({
      type: USER_PROFILE_LOADED,
      payload: userData,
    });
  }

  async reload() {
    try {
      var data = await API.graphql(
        graphqlOperation(queries.getAgileLifeUser, {
          id: this.userInfo.id,
        })
      );

      if (!data.data.getAgileLifeUser) {
        await this.createNewProfile(this.userInfo);
      } else {
        this.store.dispatch({
          type: USER_PROFILE_LOADED,
          payload: data.data.getAgileLifeUser,
        });
      }
    } catch (e) {
      alert("UserProfileAction error 115 " + JSON.stringify(e));
      await Auth.signOut();
    }
  }
}

let userProfileHelper = new UserProfileHelper(store);

function stringify(vars) {
  let vars2 = { ...vars };
  for (let id in vars2) {
    let ob = vars2[id];
    if (ob instanceof Object) vars2[id] = JSON.stringify(ob);
  }
  return vars2;
}

export function refresh() {
  return async function (dispatch) {
    await userProfileHelper.reload();
  };
}

export function updateProfile(variables) {
  return async function (dispatch) {
    await API.graphql(
      graphqlOperation(mutations.updateAgileLifeUser, {
        input: stringify(variables),
      })
    );

    dispatch({
      type: USER_PROFILE_UPDATE,
      payload: variables,
    });
  };
}

export function logout() {
  return async function (dispatch) {
    await Auth.signOut();
    userProfileHelper.destroy(); // unsubscribe to all events
    dispatch({
      type: USER_LOGGED_OUT,
    });
  };
}

export function checkForAutoLogin() {
  return async function (dispatch) {
    Auth.currentAuthenticatedUser()
      .then(() => {
        dispatch({
          type: USER_LOGGED_IN,
        });
        userProfileHelper.init();
      })
      .catch(() => {
        dispatch({
          type: USER_LOGGED_OUT,
        });
      });
  };
}

export function createNewProfile(userData, onLogin) {
  return async function (dispatch) {
    userProfileHelper.createNewProfile(userData, onLogin);
  };
}

export function onSignIn() {
  return async function (dispatch) {
    userProfileHelper.init();
  };
}

export { userProfileHelper };
