export default {
  MAX_ATTACHMENT_SIZE: 5000000,
  cognito: {
    REGION: "us-east-1",
    USER_POOL_ID: "us-east-1_55vAmWHDI", // iknow amplify def
    APP_CLIENT_ID: "406nkm39skrqhvgjlcqarn3soe",
    IDENTITY_POOL_ID: "us-east-1:c9e1724d-296f-4b13-b498-de4059994a03",
  },
  social: {
    FB: "1262082034142573",
    GOOGLE:
      "214218285202-k3vlahis0v05a3ap04rg2rth5vdj970m.apps.googleusercontent.com",
  },
  appSync: {
    REGION: "us-east-1",
    ENDPOINT:
      //"https://5zm77ie5snawnenrxyek2ldbra.appsync-api.us-east-1.amazonaws.com/graphql",
      "https://jvsmsupnkzcp5avctf53esk7l4.appsync-api.us-east-1.amazonaws.com/graphql",
  },
  apiGateway: {
    REGION: "us-east-1",
    URL: "https://cympw302u1.execute-api.us-east-1.amazonaws.com/prod",
  },
  defaultImages: {
    user: {
      resourceId: "/images/icons/blankuserimage.png",
      width: 100,
      height: 100,
      local: true,
    },
    lens: {
      resourceId: "/images/icons/lens.png",
      width: 100,
      height: 100,
      local: true,
    },
    tools: {
      resourceId: "/images/icons/tools.png",
      width: 100,
      height: 100,
      local: true,
    },
  },
};
