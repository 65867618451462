import React, { Component } from "react";
import { Auth } from "aws-amplify";
import LoaderButton from "../../components/buttons/LoaderButton";
import config from "../../config";
import { connect } from "react-redux";
import { onSignIn } from "../../reduxStores/actions/userProfileActions";

class FacebookButton extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
    };
    this.fbAsyncInit = this.fbAsyncInit.bind(this);
    this.initFB = this.initFB.bind(this);
    this.handleError = this.handleError.bind(this);
    this.statusChangeCallback = this.statusChangeCallback.bind(this);
  }

  async componentDidMount() {
    if (!window.FB) this.createScript();
  }

  fbAsyncInit() {
    const fb = window.FB;
    fb.init({
      appId: config.social.FB,
      autoLogAppEvents: true,
      xfbml: true,
      version: "v5.0",
    });

    //fb.getLoginStatus(response => logger.debug(response));
  }

  initFB() {
    const fb = window.FB;
    console.log("FB inited");
  }

  createScript() {
    window.fbAsyncInit = this.fbAsyncInit;

    const script = document.createElement("script");
    script.src = "https://connect.facebook.net/en_US/sdk.js";
    script.async = true;
    script.onload = this.initFB;
    document.body.appendChild(script);
  }

  statusChangeCallback = (response) => {
    if (response.status === "connected") {
      this.handleResponse(response.authResponse);
    } else {
      this.handleError(response);
      this.setState({ isLoading: false });
    }
  };

  checkLoginState = () => {
    window.FB.getLoginStatus(this.statusChangeCallback);
  };

  handleClick = () => {
    try {
      window.LOG_LEVEL = "DEBUG";
      window.FB.login(this.checkLoginState, {
        scope: "public_profile,email",
        return_scopes: true,
      });
      this.setState({ isLoading: true });
    } catch (e) {
      this.handleError(e);
      this.setState({ isLoading: false });
    }
  };

  handleError(error) {
    alert(JSON.stringify(error));
    this.setState({ isLoading: false });
  }

  async handleResponse(data) {
    const { accessToken: token, expiresIn } = data;
    const expires_at = expiresIn * 1000 + new Date().getTime();

    this.setState({ isLoading: true });

    var promise = new Promise(function (resolve, reject) {
      window.FB.api(
        "/me",
        { fields: "name,email,first_name,last_name,picture" },
        function (response) {
          // response has the data
          resolve(response);
        }
      );
    });

    let response = await promise;
    let picture = response.picture;

    picture = picture.data.url;
    const facebook_user = {
      id: "facebook:" + data.userID,
      name: response.name,
      email: response.email,
      given_name: response.first_name,
      family_name: response.last_name,
      profileImage: picture,
    };

    try {
      const response = await Auth.federatedSignIn(
        "facebook",
        { token, expires_at },
        facebook_user
      );

      Auth.currentAuthenticatedUser().then((user) => {
        this.props.onSignIn();
        this.setState({ isLoading: false });
      });
    } catch (e) {
      this.setState({ isLoading: false });
      this.handleError(e);
    }
  }

  render() {
    let facebookText = (
      <span>
        <div
          style={{ width: "8px", display: "inline-block", fontSize: "20px" }}
        >
          <svg viewBox="0 0 279 538" xmlns="http://www.w3.org/2000/svg">
            <g id="Page-1" fill="none" fillRule="evenodd">
              <g id="Artboard" fill="#FFF">
                <path
                  d="M82.3409742,538 L82.3409742,292.936652 L0,292.936652 L0,196.990154 L82.2410458,196.990154 L82.2410458,126.4295 C82.2410458,44.575144 132.205229,0 205.252865,0 C240.227794,0 270.306232,2.59855099 279,3.79788222 L279,89.2502322 L228.536175,89.2502322 C188.964542,89.2502322 181.270057,108.139699 181.270057,135.824262 L181.270057,196.89021 L276.202006,196.89021 L263.810888,292.836708 L181.16913,292.836708 L181.16913,538 L82.3409742,538 Z"
                  id="Fill-1"
                />
              </g>
            </g>
          </svg>
        </div>{" "}
        Login with Facebook
      </span>
    );

    return (
      <LoaderButton
        block
        bsSize="large"
        variant="primary"
        className="FacebookButton"
        text={facebookText}
        loadingText="Logging into Facebook"
        isLoading={this.state.isLoading}
        onClick={this.handleClick}
        disabled={this.state.isLoading}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  userProfileLoginState: state.userProfile.loginState,
});

export default connect(mapStateToProps, {
  onSignIn,
})(FacebookButton);
