import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Scott from "./images/scott.png";
import David3 from "./images/david3.png";
import Third from "./images/thirdPerson.png";
import smallCircle from "./images/smallcircle.png";
import SliderSection from "../components/CommonWidgets/SlideUpDownPanel/SliderSection";
import titleHeader from "../components/headers/titleHeader";
import renderFooter from "../components/headers/footer";
import Mailchimp from "../libs/Mailchimp";
import bookCover from "./images/mockupFinal.jpg";

export default function FreeStuff() {
  function renderHeader() {
    return titleHeader(
      "Free Stuff",
      "Sign up to our mailing list to receive our free eBook, and weekly newsletter and videos."
    );
  }

  return (
    <div>
      {renderHeader()}
      <div style={{ borderTop: "3px solid white" }}>
        <div
          className="blackOnWhiteText paddedTopAndBottom"
          style={{
            overflowX: "hidden",
            overflowY: "hidden",
            marginTop: "15px",
          }}
        >
          <Container>
            <Row>
              <Col md={6}>
                <div>
                  <h3 className="text-danger text-center">
                    <b>FREE EBOOK:</b>
                  </h3>
                  <div>
                    <img src={bookCover} style={{ width: "100%" }}></img>
                  </div>
                </div>
              </Col>
              <Col md={6}>
                <h4>LEADING THROUGH TURBULENT TIMES</h4>
                <h5>
                  3 essential practices for surviving and thriving during
                  COVID-19 and beyond
                </h5>
                <p>
                  In this <b>free ebook</b> we cover simple but{" "}
                  <b>profoundly effective strategies</b> for:
                  <ul>
                    <li>Navigating complex challenges</li>
                    <li>Connecting with, leading, and working with others</li>
                    <li>
                      Cultivating courage, in order to do what matters most
                    </li>
                  </ul>
                </p>
                <p>
                  Subscribe to our mailing list below to receive this free
                  eBook, and also receive our weekly newsletter and videos.
                </p>
                <h4>Subscribe</h4>

                <Mailchimp
                  action="https://qut.us9.list-manage.com/subscribe/post?u=c087bef7a98ad8e4f85890553&amp;id=eb1fd76d0a"
                  fields={[
                    {
                      name: "EMAIL",
                      placeholder: "Email",
                      type: "email",
                      required: true,
                    },
                    {
                      name: "FNAME",
                      placeholder: "First name",
                      type: "text",
                      required: true,
                    },
                    {
                      name: "LNAME",
                      placeholder: "Last name",
                      type: "text",
                      required: true,
                    },
                  ]}
                  messages={{
                    sending: "Sending...",
                    success:
                      "Thank you for subscribing!  You will receive a confirmation email shortly, where you can download our free eBook :)  Please check your spam folder if it doesn't arrive in the near future.",
                    error: "An unexpected internal error has occurred.",
                    empty: "Please enter all fields.",
                    duplicate: "You have already subscribed to this newsletter",
                    button: "Subscribe!",
                  }}
                  className={"emailSignup"}
                />
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      {renderFooter()}
    </div>
  );
}
