import React, { Component } from "react";
import LoaderButton from "../buttons/LoaderButton";
import Joi from "joi-browser";

import Input from "./input";
import TextArea from "./textArea";
import Select from "./select";

export default class InputForm extends Component {
  state = {
    data: {},
    errors: {},
  };

  validate = () => {
    const options = {
      abortEarly: false,
    };
    const { error } = Joi.validate(this.state.data, this.schema, options);
    if (!error) return null;

    let errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;

    return errors;
  };

  validateExtra = () => {
    // overridden ... allows form to check for additiona errors
    return null;
  };

  validateProperty = ({ name, value }) => {
    const obj = { [name]: value };
    const schema = { [name]: this.schema[name] };
    const { error } = Joi.validate(obj, schema);

    return error ? error.details[0].message : null;
  };

  handleSubmit = (e) => {
    e.preventDefault(); // stop it from submitting!

    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors) return;
    this.doSubmit();
  };

  handleChange = ({ target: input }) => {
    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty(input);
    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];

    const data = { ...this.state.data };
    data[input.name] = input.value;
    this.setState({ data, errors });
  };

  renderButton(text, color, loadingText, isLoading) {
    const errors = this.validate();
    const additionalErrors = this.validateExtra();

    return (
      <React.Fragment>
        {additionalErrors && (
          <div className="alert alert-danger">{additionalErrors}</div>
        )}
        <LoaderButton
          onClick={this.handleSubmit}
          className={"btn-block " + color}
          bsSize="large"
          text={text}
          variant="success"
          loadingText={loadingText}
          disabled={errors || additionalErrors}
          isLoading={isLoading}
        />
      </React.Fragment>
    );
  }

  renderInput(name, label, type, autoFocus) {
    const { data, errors } = this.state;
    return (
      <Input
        label={label}
        name={name}
        type={type}
        value={data[name]}
        onChange={this.handleChange}
        error={errors[name]}
        autoFocus={autoFocus}
      />
    );
  }
  renderTextArea(name, label) {
    const { data, errors } = this.state;
    return (
      <TextArea
        label={label}
        name={name}
        value={data[name]}
        onChange={this.handleChange}
        error={errors[name]}
      />
    );
  }

  renderDropdown({ name, label, options }) {
    const { data, errors } = this.state;
    return (
      <Select
        label={label}
        name={name}
        value={data[name]}
        onChange={this.handleChange}
        error={errors[name]}
        options={options}
      />
    );
  }

  renderVariables(variableList) {
    if (!variableList) return [];
    let output = [];
    variableList.forEach((item) => {
      if (item.type === "input")
        output.push(
          this.renderInput(item.name, item.label, "text", item.autoFocus)
        );
      if (item.type === "textArea")
        output.push(this.renderTextArea(item.name, item.label));
      if (item.type === "dropdown") output.push(this.renderDropdown(item));
    });
    return output;
  }
}
