import React, { Component } from "react";
import { Navbar, Nav, Dropdown } from "react-bootstrap";
import "./memberHeader.css";
import headerLogo from "../images/headerlogo.png";
import { connect } from "react-redux";
import { logout } from "../../reduxStores/actions/userProfileActions";
import config from "../../config";
import { USER_PROFILE_LOADING } from "../../reduxStores/actions/userProfileActions";

class MemberHeader extends Component {
  getUserFirstName() {
    if (this.props.loadingState === "USER_PROFILE_LOADING") {
      return "???";
    }
    return this.props.userProfile.firstName || "";
  }

  getUserImage() {
    if (this.props.loadingState === USER_PROFILE_LOADING) {
      return config.defaultImages.user.resourceId;
    }

    if (this.props.userProfile.profileImage) {
      let image = this.props.userProfile.profileImage.resourceId;
      if (image && image.indexOf("http") >= 0) return image;
      return config.s3.URL + image;
    }
    return config.defaultImages.user.resourceId;
  }

  render() {
    return (
      <div className="memberHeader">
        <Navbar>
          <img
            src={headerLogo}
            className="d-inline-block align-top logo"
            alt="The Agile Life Logo"
          />
          <ul className="mr-auto" />

          <span className="navbar-text d-none d-md-block navbar-text-custom">
            {this.getUserFirstName()}
          </span>

          <img className="navbar-brand userImage" src={this.getUserImage()} />

          <Dropdown style={{ marginRight: "3px" }}>
            <Dropdown.Toggle className="bg-light text-dark">
              <span className="navbar-toggler-icon" />
            </Dropdown.Toggle>
            <Dropdown.Menu className="dropdown-menu-right">
              <Dropdown.Item onClick={() => this.props.logout()}>
                Logout
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Navbar>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  userProfile: state.userProfile.userInfo,
  loginState: state.userProfile.loginState,
  loadingState: state.userProfile.loadingState,
});

export default connect(mapStateToProps, {
  logout,
})(MemberHeader);
