import React, { Component } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import titleHeader from "../../components/headers/titleHeader";
import renderFooter from "../../components/headers/footer";
import { sendWebinarLink } from "../../libs/awsLib";
import InputForm from "../../components/formElements/inputForm";
import Joi from "joi-browser";
import ReactGA from "react-ga";

export default class FreeWebinar extends InputForm {
  state = {
    data: {
      email: "",
      firstName: "",
      lastName: "",
    },
    visiblePanel: "SIGNUP",
    errors: {},
    submitting: false,
    submitError: null,
  };

  schema = {
    email: Joi.string().email().required().label("Email"),
    firstName: Joi.string().min(2).max(100).required().label("First Name"),
    lastName: Joi.string().min(2).max(100).required().label("Last Name"),
  };

  componentDidMount() {
    ReactGA.event({
      category: "WebinarUser",
      action: "Viewed Free Webinar Page",
    });
  }

  doSubmit = async function () {
    const { firstName, lastName, email } = this.state.data;
    this.setState({ submitting: true });

    try {
      const result = await sendWebinarLink(firstName, lastName, email);
      this.setState({
        submitting: false,
        visiblePanel: "LINKSENT",
        submitError: null,
      });
      ReactGA.event({
        category: "WebinarUser",
        action: "Submitted Email Success",
      });
    } catch (e) {
      console.log("SUBMIT ERROR:", e);
      this.setState({
        submitting: false,
        visiblePanel: "LINKSENT",
        submitError: e,
      });
      ReactGA.event({
        category: "WebinarUser",
        action: "Submitted Email Failed",
      });
    }
  };

  renderHeader = () => {
    return titleHeader(
      "WEBINAR",
      "Succeeding Scientifically - 3 Breakthrough Strategies for Effectively Accomplishing Your Goals"
    );
  };

  renderSignUp = () => {
    return (
      <div>
        <p>
          Please enter your details below, and we will send you a link to our
          FREE 30 minute webinar!
        </p>
        <form onSubmit={this.handleSubmit}>
          {this.renderInput("firstName", "First Name", "text")}
          {this.renderInput("lastName", "Last Name", "text")}
          {this.renderInput("email", "Email", "text", "autofocus")}
          {this.renderButton(
            "SIGN ME UP",
            this.props.buttonColour,
            "Submitting...",
            this.state.submitting
          )}
        </form>
      </div>
    );
  };

  showSignUp = () => {
    const { visiblePanel } = this.state;
    return (
      <div>
        <div className="image-and-content">
          <div className="image-and-content__image d-md-none d-lg-block">
            <img style={{ opacity: 0.2 }} src="images/mountain2.jpg" />
          </div>
          <div className="image-and-content__container paddedEdges">
            <div className="image-and-content__content_full">
              <Container>
                <Row>
                  <Col className="image-and-content__cell" md={6}>
                    <h4>The Challenge</h4>
                    <p>
                      Are you a business owner, manager, executive or leader?{" "}
                    </p>
                    <p>
                      Do you have current challenges that seem overwhelming -
                      issues that you can't seem to think your way out of?
                    </p>
                    <p>
                      Having problems seeing the big picture, and a clear course
                      of action?
                    </p>
                    <p>
                      You are not alone! - 92% of us do not achieve the goals we
                      set for ourselves. And there is a better way!{" "}
                    </p>
                    <p>
                      In this FREE 30 minute webinar you will learn simple
                      evidence-based strategies taught at the world's leading
                      business schools that will help you better navigate your
                      way forward in times of uncertainty, complexity or change.
                    </p>
                    <p>I look forward to seeing you there!</p>
                    <p>Dr Scott Bolland, PhD</p>
                  </Col>
                  <Col className="image-and-content__cell2" md={6}>
                    <h4>FREE WEBINAR</h4>

                    {visiblePanel === "SIGNUP" && this.renderSignUp()}
                    {visiblePanel === "LINKSENT" && this.renderLinkSent()}
                  </Col>
                </Row>
              </Container>
            </div>
          </div>
        </div>
      </div>
    );
  };

  viewWebinarNow = () => {
    ReactGA.event({
      category: "WebinarUser",
      action: "View Webinar Now Clicked",
    });
    window.location.href = "https://www.theagilelife.com.au/#webinar";
  };

  showLinkSentError = () => {
    let { email } = this.state.data;

    return (
      <div>
        <h1>OOPS</h1>
        <p>
          We have had some issues sending an email to the following address:{" "}
          <i>{email}</i>
        </p>
        <p>Please use the below button to view the webinar now</p>
        <p>
          <Button
            className="bookNow"
            variant="success"
            onClick={this.viewWebinarNow}
          >
            VIEW WEBINAR NOW
          </Button>
        </p>
        <p>Or click the below button to try submitting your details again</p>
        <Button
          className="bookNow"
          onClick={() => {
            this.setState({ visiblePanel: "SIGNUP" });
          }}
        >
          try again
        </Button>
      </div>
    );
  };

  renderLinkSent = () => {
    let { email } = this.state.data;
    if (this.state.submitError) return this.showLinkSentError();

    return (
      <div>
        <h1>AWESOME</h1>
        <p>
          We have sent a link to view the webinar to your email address:{" "}
          <i>{email}</i>
        </p>
        <p>
          If this address is correct, it should arrive within a few minutes.
          Please check your spam folder, if it doesn't arrive shortly
        </p>
        <p>Or, you can use the below button to view the webinar now</p>
        <Button
          variant="success"
          className="bookNow"
          onClick={this.viewWebinarNow}
        >
          VIEW WEBINAR NOW
        </Button>
      </div>
    );
  };

  render() {
    return (
      <div className="frontPageContent">
        {this.renderHeader()}
        <div style={{ borderTop: "3px solid white" }}>
          <div
            className="blackOnWhiteText paddedTopAndBottom"
            style={{
              overflowX: "hidden",
              overflowY: "hidden",
              marginTop: "15px",
            }}
          >
            {this.showSignUp()}
          </div>
        </div>
        {renderFooter()}
      </div>
    );
  }
}
