const USER_LOGGED_IN = "USER_LOGGED_IN";
const USER_LOGGED_OUT = "USER_LOGGED_OUT";
const USER_PROFILE_LOADING = "USER_PROFILE_LOADING";
const USER_PROFILE_LOADED = "USER_PROFILE_LOADED";
const USER_PROFILE_UPDATE = "USER_PROFILE_UPDATE";
const USER_STATE_UNKNOWN = "USER_STATE_UNKNOWN";

const initialState = {
  loginState: USER_STATE_UNKNOWN,
  loadingState: USER_PROFILE_LOADING,
  onLoadUrl: null,
  accountProvider: null,
  userInfo: {},
  federatedUser: {},
  fullName: "",
  enableOpenAccess: false,
  locale: Intl.NumberFormat().resolvedOptions().locale,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case USER_LOGGED_OUT:
      return {
        ...state,
        loginState: USER_LOGGED_OUT,
      };
    case USER_LOGGED_IN:
      return {
        ...state,
        loginState: USER_LOGGED_IN,
      };

    case USER_PROFILE_LOADING:
      return {
        ...state,
        loadingState: USER_PROFILE_LOADING,
        userInfo: null,
        invitations: [],
      };

    case USER_PROFILE_LOADED:
      let userProfile = action.payload;
      if (userProfile.profileImage)
        userProfile.profileImage = JSON.parse(userProfile.profileImage);

      return {
        ...state,
        loadingState: USER_PROFILE_LOADED,
        userInfo: userProfile,
        fullName:
          userProfile.firstName +
          " " +
          userProfile.lastName +
          " (" +
          userProfile.email +
          ")",
      };

    case USER_PROFILE_UPDATE:
      let variables = action.payload;
      let userInfo = state.userInfo;
      for (var i in variables) {
        userInfo[i] = variables[i];
      }
      return {
        ...state,
        userInfo: userInfo,
      };

    default:
      return state;
  }
}
