import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import agileLife from "./images/agileLifeDiagram2.png";
import LoginPanel from "../components/login/LoginPanel";
import smallCircle from "./images/smallcircle.png";

export default class MemberLogin extends Component {
  render() {
    return (
      <div>
        <Container>
          <div className="text-center pageHeader">
            <h1>Member Login</h1>
          </div>
        </Container>
        <div className="whiteBackground">
          <h3>
            <b>The Agile Life Framework</b>
          </h3>
          <h4>
            A comprehensive toolkit for tackling complex business challenges
          </h4>
          <Container>
            <Row>
              <Col className="profileImage" md={6}>
                <img
                  style={{
                    width: "100%",
                    maxWidth: "400px",
                    textAlign: "center",
                  }}
                  src={agileLife}
                  alt="agile life diagram"
                />
                <p className="text-center">
                  Create a FREE account to explore example tools and lenses for
                  exploring complex managerial and leadership problems
                </p>
              </Col>
              <Col md={6} className="profileText">
                <LoginPanel />
              </Col>
            </Row>
          </Container>
        </div>
        <div className="footerText text-right">
          <img src={smallCircle} alt="logo" /> The Agile Life
        </div>
      </div>
    );
  }
}
