import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";
import { hideOKMessage } from "../../../reduxStores/actions/PopupMessagesActions";
import { connect } from "react-redux";

class OKMessagePopup extends Component {
  render() {
    let visible = this.props.okMessage != null;
    return (
      <div className="OkMessagePopup">
        <Modal show={visible} onHide={this.props.hideOKMessage} size="lg">
          <Modal.Header closeButton>
            <div className="text-success">Success</div>
          </Modal.Header>

          <Modal.Body>{this.props.okMessage}</Modal.Body>
          <Modal.Footer>
            <Button variant="success" onClick={this.props.hideOKMessage}>
              OK
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  okMessage: state.popupMessages.okMessage,
});

export default connect(mapStateToProps, {
  hideOKMessage,
})(OKMessagePopup);
