import React, { Component } from "react";
import { Navbar, Nav, Dropdown } from "react-bootstrap";
import "./header.css";
import headerLogo from "../images/headerlogo.png";

const memberArea = false;

export default class PageHeader extends Component {
  render() {
    return (
      <div className="pageHeader">
        <Navbar>
          <img
            src={headerLogo}
            className="d-inline-block align-top logo"
            alt="The Agile Life Logo"
          />

          <Nav
            className="mr-auto"
            activeKey={this.props.activePage}
            onSelect={(selectedKey) => this.props.onSelect(selectedKey)}
          >
            <Nav.Link className="d-none d-sm-block" href="#home">
              Home
            </Nav.Link>
            <Nav.Link className="d-none d-md-block" href="#services">
              Services
            </Nav.Link>
            <Nav.Link className="d-none d-md-block" href="#about">
              About Us
            </Nav.Link>
            <Nav.Link className="d-none d-md-block" href="#book">
              Free Book
            </Nav.Link>
            <Nav.Link className="d-none d-md-block" href="#newsletter">
              Newsletter
            </Nav.Link>
            <Nav.Link className="d-none d-sm-block" href="#contact">
              Contact Us
            </Nav.Link>
            {memberArea && (
              <Nav.Link className="d-none d-lg-block" href="#members">
                For Members
              </Nav.Link>
            )}
          </Nav>

          <Dropdown
            className="d-block d-lg-none"
            style={{ marginRight: "3px" }}
          >
            <Dropdown.Toggle className="bg-light text-dark">
              <span className="navbar-toggler-icon" />
            </Dropdown.Toggle>
            <Dropdown.Menu className="dropdown-menu-right">
              <Dropdown.Item onClick={() => this.props.onSelect("#home")}>
                Home
              </Dropdown.Item>
              <Dropdown.Item onClick={() => this.props.onSelect("#services")}>
                Services
              </Dropdown.Item>
              <Dropdown.Item onClick={() => this.props.onSelect("#about")}>
                About Us
              </Dropdown.Item>
              <Dropdown.Item onClick={() => this.props.onSelect("#book")}>
                Free Book
              </Dropdown.Item>
              <Nav.Link className="d-none d-md-block" href="#newsletter">
                Newsletter
              </Nav.Link>
              <Dropdown.Item onClick={() => this.props.onSelect("#contact")}>
                Contact Us
              </Dropdown.Item>
              {memberArea && (
                <Dropdown.Item onClick={() => this.props.onSelect("#members")}>
                  For Members
                </Dropdown.Item>
              )}
            </Dropdown.Menu>
          </Dropdown>
        </Navbar>
      </div>
    );
  }
}
