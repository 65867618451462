import React, { useState } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from "react-google-maps";
import { sendEmail } from "../libs/awsLib";
import titleHeader from "../components/headers/titleHeader";
import renderFooter from "../components/headers/footer";
import { Envelope, CameraVideo } from "react-bootstrap-icons";

const googleApiKey = "AIzaSyBtTvRl-G7Y-zag7xp8JnH6uKRzxqLP3mQ";

const MyMapComponent = withScriptjs(
  withGoogleMap((props) => (
    <GoogleMap
      defaultZoom={3}
      defaultCenter={{ lat: -27.4698, lng: 153.0251 }}
      defaultOptions={{
        minZoom: 2,
        maxZoom: 6,
      }}
    >
      <Marker position={{ lat: -27.4698, lng: 153.0251 }} />
    </GoogleMap>
  ))
);

export default function ContactUs(props) {
  const [validated, setValidated] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [query, setQuery] = useState("");

  const openBookingInNewTab = () => {
    window
      .open("https://appt.link/drscottbolland/discovery-call", "_blank")
      .focus();
  };

  function renderContactForm() {
    const handleSubmit = (event) => {
      const form = event.currentTarget;

      event.preventDefault();
      event.stopPropagation();

      if (form.checkValidity() === true) {
        sendEmail(firstName, lastName, email, phone, query);
        setEmailSent(true);
      }

      setValidated(true);
    };

    if (emailSent)
      return (
        <div>
          <h1 className="text-center">Contact Us</h1>
          <div className="centredTextBox">
            <p>Thank you for your enquiry!</p>
            <p>
              If the email address you entered ({email}) was correct, we will
              send you a confirmation email in the next few minutes, and get
              back to you soon!
            </p>

            <div className="submitButton">
              <Button
                onClick={() => {
                  setEmailSent(false);
                  setValidated(false);
                }}
                className="topMargin submitButton"
                variant="primary"
              >
                OK
              </Button>
            </div>
          </div>
        </div>
      );

    return (
      <div>
        <h1 className="text-center">Contact Us</h1>
        <div className="centredTextBox">
          For more information about our products and services, please email us
          at{" "}
          <a href="mailto:enquiries@theagilelife.com.au">
            enquiries@theagilelife.com.au
          </a>{" "}
          or use the form below and we will get back to you shortly.
        </div>

        <Container>
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Form.Row>
              <Col md={6} lg={3} className="topMargin">
                <Form.Group>
                  <Form.Control
                    required
                    type="text"
                    placeholder="First name"
                    name="firstName"
                    onChange={(e) => setFirstName(e.target.value)}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please enter your first name
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={6} lg={3} className="topMargin">
                <Form.Group>
                  <Form.Control
                    required
                    type="text"
                    placeholder="Last name"
                    name="lastName"
                    onChange={(e) => setLastName(e.target.value)}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please enter your last name
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={6} lg={3} className="topMargin">
                <Form.Group>
                  <Form.Control
                    name="email"
                    required
                    type="email"
                    placeholder="Email address"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please enter a valid email address
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={6} lg={3} className="topMargin">
                <Form.Group>
                  <Form.Control
                    required
                    type="text"
                    placeholder="phone"
                    name="phone"
                    onChange={(e) => setPhone(e.target.value)}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please enter your phone number
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Form.Row>
            <Row>
              <Col className="topMargin">
                <Form.Group>
                  <Form.Control
                    required
                    as="textarea"
                    rows="6"
                    placeholder="Enter your query"
                    name="query"
                    onChange={(e) => setQuery(e.target.value)}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please enter your query
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <div className="submitButton">
              <Button
                className="topMargin submitButton"
                variant="primary"
                type="submit"
              >
                Submit
              </Button>
            </div>
          </Form>
        </Container>
      </div>
    );
  }

  function renderHeader() {
    return titleHeader(
      "Contact Us",
      "Although The Agile Life is based in Brisbane Australia, we are available for in-person or remote delivery worldwide. Please contact us with any questions or to book a free consultation."
    );
  }

  function renderContactForm2() {
    return (
      <div>
        <h1 className="text-center">Contact</h1>
        <div className="contactTextBox">
          <p className="text-center">
            For more information about my products and services:
          </p>
          <table>
            <tbody>
              <tr>
                <td>
                  <CameraVideo size={30} />
                </td>
                <td>
                  Please use the below button to book a free half hour
                  "discovery" session (via zoom):
                </td>
              </tr>
              <tr>
                <td></td>
                <td className="text-center">
                  <Button onClick={openBookingInNewTab}>
                    Book a Time to Chat
                  </Button>
                </td>
              </tr>
              <tr>
                <td>
                  <Envelope size={30} />
                </td>
                <td>
                  Or please email me at{" "}
                  <a href="mailto:scott@scottbolland.com">
                    scott@scottbolland.com
                  </a>{" "}
                  and I will get back to you shortly.
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  }

  return (
    <div className="frontPageContent">
      {renderHeader()}
      <div
        className="whiteOnBlackText paddedTopAndBottom"
        style={{
          overflowX: "hidden",
          overflowY: "hidden",
          marginTop: "15px",
          borderTop: "3px solid white",
        }}
      >
        {renderContactForm2()}
      </div>
      <div style={{ borderTop: "3px solid white" }}>
        <div className="blackOnWhiteText">
          <div className="paddedEdges paddedTopAndBottom">
            <MyMapComponent
              isMarkerShown
              googleMapURL={
                "https://maps.googleapis.com/maps/api/js?maxZoom=10&key=" +
                googleApiKey +
                "&v=3.exp&libraries=geometry,drawing,places"
              }
              loadingElement={<div style={{ height: `100%` }} />}
              containerElement={<div style={{ height: `400px` }} />}
              mapElement={<div style={{ height: `100%` }} />}
            />
          </div>
        </div>
      </div>
      {renderFooter()}
    </div>
  );
}
