import React, { Component } from "react";
import "./editor.css";
import { Container, Tabs, Tab, Button, Row, Col } from "react-bootstrap";
import KeywordSearch from "../components/search/keywordSearch";

class ToolEditor extends Component {
  constructor(props) {
    super(props);
    let content = JSON.parse(JSON.stringify(this.props.currentTool));
    this.state = {
      content: content,
    };
  }

  handleChange = (event) => {
    let value = event.target.value;
    let content = {
      ...this.state.content,
      [event.target.id]: value,
    };

    this.setState({
      content: content,
    });
  };

  updateKeywords(newList) {
    let content = {
      ...this.state.content,
      keywords: newList,
    };

    this.setState({
      content: content,
    });
  }

  renderAbout() {
    return (
      <div>
        <h4>Lens Name:</h4>
        <input
          id="name"
          value={this.state.content.name}
          onChange={this.handleChange}
        />
        <h4>Lens Short Description:</h4>
        <input
          id="shortDescription"
          value={this.state.content.shortDescription}
          onChange={this.handleChange}
        />
        <h4>When to Use:</h4>
        <input
          id="whenToUse"
          value={this.state.content.whenToUse}
          onChange={this.handleChange}
        />
        <h4>Keywords:</h4>
        <KeywordSearch
          keywords={this.state.content.keywords}
          onChange={(newList) => this.updateKeywords(newList)}
          library="allTools"
        />
        <h4>References:</h4>
      </div>
    );
  }

  renderContent() {
    return (
      <Container>
        <Tabs defaultActiveKey="about" id="lensEditor">
          <Tab eventKey="about" title="About" className="whitePanel">
            {this.renderAbout()}
          </Tab>
          <Tab eventKey="filter" title="Filter" className="whitePanel">
            <p>this is a description of the filter</p>
          </Tab>
          <Tab eventKey="example" title="Example" className="whitePanel">
            <p>this is a description of an example</p>
          </Tab>
          <Tab
            eventKey="limitations"
            title="Limitations"
            className="whitePanel"
          >
            <p>Any limitations you can think of</p>
          </Tab>
        </Tabs>
      </Container>
    );
  }

  render() {
    return (
      <div className="GenericPopup">
        <div className="card GenericPopupInner">
          <div className="header bg-dark">
            <div className="d-inline-block title">
              <span className="text-light">Tool Editor</span>{" "}
            </div>
            <div className="float-right">
              <button
                className="btn btn-success"
                onClick={() => this.props.onSave(this.state.content)}
              >
                Save and Close
              </button>{" "}
              <button
                className="btn btn-danger"
                onClick={() => this.props.onClose()}
              >
                Close
              </button>
            </div>
          </div>
          <div className="bodyArea">{this.renderContent()}</div>
        </div>
      </div>
    );
  }
}

export default ToolEditor;
