import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import { logout } from "../../reduxStores/actions/userProfileActions";
import {
  loadDatabase,
  TOOLKIT_LOADED,
} from "../../reduxStores/actions/lensAndToolkitDatabaseActions";
import Header from "../../components/headers/memberHeader";
import {
  USER_PROFILE_LOADING,
  USER_PROFILE_LOADED,
} from "../../reduxStores/actions/userProfileActions";
import LensAndToolEditor from "../../containers/LensAndToolEditors";
import ContactUs from "../ContactUs";

class MemberArea extends Component {
  componentDidMount() {
    this.props.loadDatabase();
  }

  render() {
    return (
      <div>
        {this.props.loadingState === TOOLKIT_LOADED && <LensAndToolEditor />}
        {this.props.loadingState !== TOOLKIT_LOADED && (
          <div>Loading Toolkit...</div>
        )}
        <ContactUs />
      </div>
    );
  }
}

const mapStateToProps2 = (state) => ({
  loadingState: state.lensAndToolDatabase.status,
});

MemberArea = connect(mapStateToProps2, { loadDatabase })(MemberArea);

class MemberAreaHome extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activePage: "#home",
    };
  }
  logout() {
    this.props.logout();
  }

  renderMain() {
    return (
      <div>
        <MemberArea />
      </div>
    );
  }

  renderLoading() {
    return <h2>Loading...</h2>;
  }

  render() {
    return (
      <div>
        <Header
          activePage={this.state.activePage}
          onSelect={(page) => this.setState({ activePage: page })}
        />
        <div className="contents">
          <Container className="pageHeader">
            <div className="text-center">
              <h1>Member Area</h1>
            </div>
          </Container>

          <div className="whiteBackground">
            {this.props.loadingState === USER_PROFILE_LOADED &&
              this.renderMain()}
            {this.props.loadingState === USER_PROFILE_LOADING &&
              this.renderLoading()}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  loggedInState: state.userProfile.loginState,
  loadingState: state.userProfile.loadingState,
});

export default connect(mapStateToProps, { logout })(MemberAreaHome);
