import { v4 as uuidv4 } from "uuid";
import store from "../store";
import * as mutations from "../../graphql/mutations";
import * as queries from "../../graphql/queries";
import { API, Auth, graphqlOperation } from "aws-amplify";

export const TOOLKIT_SAVE_LENS = "TOOLKIT_SAVE_LENS";
export const TOOLKIT_SAVE_TOOL = "TOOLKIT_SAVE_TOOL";
export const TOOLKIT_UPDATE_LENS_SEARCH_RESULTS =
  "TOOLKIT_UPDATE_LENS_SEARCH_RESULTS";
export const TOOLKIT_UPDATE_TOOL_SEARCH_RESULTS =
  "TOOLKIT_UPDATE_TOOL_SEARCH_RESULTS";
export const TOOLKIT_LOADED = "TOOLKIT_LOADED";

export function newLens() {
  return {
    id: uuidv4(),
    name: "New Lens",
    shortDescription: "Enter a short description",
    whenToUse: "Describe when to use",
    keywords: ["lens"],
  };
}

export function newTool() {
  return {
    id: uuidv4(),
    name: "New Tool",
    shortDescription: "Enter a short description",
    whenToUse: "Describe when to use",
    keywords: ["tool"],
  };
}

function getStringifiedObject(ob) {
  let newOb = { ...ob };
  for (let v in ob) {
    let type = typeof ob[v];
    if (type !== "string") newOb[v] = JSON.stringify(ob[v]);
  }
  return newOb;
}

function calculateOverlap(keywords1, keywords2) {
  let match = 0;
  for (var i = 0; i < keywords1.length; i++) {
    let k = keywords1[i];
    if (keywords2.indexOf(k) >= 0) match++;
  }
  return match;
}

export function findLenses(keywords) {
  return async function (dispatch) {
    // iterate through the list of items, and if >=1 match, add to list
    let state = store.getState();
    let lenses = state.lensAndToolDatabase.allLenses;
    let matches = [];
    for (var l in lenses) {
      let lens = lenses[l];
      let overlap = calculateOverlap(keywords, lens.keywords);
      if (overlap > 0) {
        matches.push({ id: lens.id, match: overlap });
      }
    }
    matches.sort(function (a, b) {
      return b.match - a.match;
    });

    dispatch({
      type: TOOLKIT_UPDATE_LENS_SEARCH_RESULTS,
      payload: matches,
    });
  };
}

export function findTools(keywords) {
  return async function (dispatch) {
    // iterate through the list of items, and if >=1 match, add to list
    let state = store.getState();
    let tools = state.lensAndToolDatabase.allTools;
    let matches = [];
    for (var t in tools) {
      let tool = tools[t];
      let overlap = calculateOverlap(keywords, tool.keywords);
      if (overlap > 0) {
        matches.push({ id: tool.id, match: overlap });
      }
    }
    matches.sort(function (a, b) {
      return b.match - a.match;
    });

    dispatch({
      type: TOOLKIT_UPDATE_TOOL_SEARCH_RESULTS,
      payload: matches,
    });
  };
}

export function saveLens(lens) {
  return async function (dispatch) {
    dispatch({
      type: TOOLKIT_SAVE_LENS,
      payload: lens,
    });

    // lets update search results too!
    let state = store.getState();
    let results = state.lensAndToolDatabase.lensSearchResults;
    let found = false;
    for (var r = 0; r < results.length; r++) {
      if (results[r].id === lens.id) found = true;
    }
    let dataToSave = getStringifiedObject(lens);
    if (!found) {
      let newResults = [{ id: lens.id, match: 1.1 }, ...results];
      // this is a new item ... so save new item to server
      dispatch({
        type: TOOLKIT_UPDATE_LENS_SEARCH_RESULTS,
        payload: newResults,
      });

      // lets save new object
      try {
        await API.graphql(
          graphqlOperation(mutations.createLens, {
            input: dataToSave,
          })
        );
      } catch (e) {
        console.log(e);
        alert("failed creating lens " + JSON.stringify(e));
      }
    } else {
      // else call update to server
      // lets save new object
      try {
        await API.graphql(
          graphqlOperation(mutations.updateLens, {
            input: dataToSave,
          })
        );
      } catch (e) {
        console.log(e);
        alert("failed updating lens " + JSON.stringify(e));
      }
    }
  };
}

export function saveTool(tool) {
  return async function (dispatch) {
    dispatch({
      type: TOOLKIT_SAVE_TOOL,
      payload: tool,
    });

    // lets update search results too!
    let state = store.getState();
    let results = state.lensAndToolDatabase.toolSearchResults;
    let found = false;
    for (var r = 0; r < results.length; r++) {
      if (results[r].id === tool.id) found = true;
    }
    let dataToSave = getStringifiedObject(tool);
    if (!found) {
      let newResults = [{ id: tool.id, match: 1.1 }, ...results];
      // this is a new item ... so save new item to server
      dispatch({
        type: TOOLKIT_UPDATE_TOOL_SEARCH_RESULTS,
        payload: newResults,
      });

      // lets save new object
      try {
        await API.graphql(
          graphqlOperation(mutations.createTool, {
            input: dataToSave,
          })
        );
      } catch (e) {
        console.log(e);
        alert("failed creating tool " + JSON.stringify(e));
      }
    } else {
      // else call update to server
      // lets save new object
      try {
        await API.graphql(
          graphqlOperation(mutations.updateTool, {
            input: dataToSave,
          })
        );
      } catch (e) {
        console.log(e);
        alert("failed updating tool " + JSON.stringify(e));
      }
    }
  };
}

function unstringifyLens(item) {
  item.keywords = JSON.parse(item.keywords);
  if (item.filterCharacteristics)
    item.filterCharacteristics = JSON.parse(item.filterCharacteristics);
  return item;
}

function unstringifyTool(item) {
  item.keywords = JSON.parse(item.keywords);
  return item;
}

export function loadDatabase() {
  return async function (dispatch) {
    // step 1 ... load all lenses
    try {
      let lensList = await API.graphql(graphqlOperation(queries.listLenss));
      let items = lensList.data.listLenss.items;

      lensList = {};
      let lensSearchResults = [];
      for (var i = 0; i < items.length; i++) {
        let item = items[i];
        lensList[item.id] = unstringifyLens(item);
      }

      let toolsList = await API.graphql(graphqlOperation(queries.listTools));
      items = toolsList.data.listTools.items;

      toolsList = {};
      let toolSearchResults = [];
      for (var i = 0; i < items.length; i++) {
        let item = items[i];
        toolsList[item.id] = unstringifyTool(item);
      }

      dispatch({
        type: TOOLKIT_LOADED,
        allLenses: lensList,
        lensSearchResults: lensSearchResults,
        allTools: toolsList,
        toolSearchResults: toolSearchResults,
      });
    } catch (e) {
      console.log(e);
      alert("failed to load lens list " + JSON.stringify(e));
    }
  };
}
