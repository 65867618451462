import React, { Component } from "react";
import { Button } from "react-bootstrap";
import LoaderButton from "../buttons/LoaderButton";
import "./LoginPanel.css";
import { Auth, API, graphqlOperation } from "aws-amplify";
import * as mutations from "../../graphql/mutations";

import { connect } from "react-redux";
import { showErrorMessage } from "../../reduxStores/actions/PopupMessagesActions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import JInput from "../CommonWidgets/JInput/JInput";
import { createNewProfile } from "../../reduxStores/actions/userProfileActions";
import {
  showPrivacyPolicy,
  showTermsOfService,
} from "../../reduxStores/actions/PopupMessagesActions";

class NewAccountPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      phase: "signUp",
      email: "",
      password: "",
      newpassword: "",
      newpassword2: "",
      facebookLogin: false,
      firstName: "",
      lastName: "",
      myemail: "",
      mypassword: "",
      mypassword2: "",
      agree: false,
    };
    this.signUp = this.signUp.bind(this);
  }

  handleChange = (event) => {
    let value = event.target.value;
    if (event.target.type === "email") value = value.toLowerCase();
    if (event.target.type === "checkbox") value = event.target.checked;

    this.setState({
      [event.target.id]: value,
    });
  };

  showSignUpForm() {
    this.setState({
      phase: "signUp",
    });
  }

  validatePassword() {
    let password = this.state.mypassword.trim();
    if (password.length < 8)
      return "passwords need to contain at least 8 characters";
    return "";
  }

  validatePasswordMatch() {
    const password1 = this.state.mypassword;
    const password2 = this.state.mypassword2;
    if (password1 !== password2) return "passwords must match";
    return "";
  }

  renderBackToLogin() {
    let caption = this.props.backButtonText || "Back to login";
    return (
      <div className="BackToHomeSubNav" onClick={this.props.clickBackToLogin}>
        <FontAwesomeIcon icon="chevron-left" /> {caption}
      </div>
    );
  }

  signUpformIsValid() {
    var errors =
      this.validateName(this.state.firstName) +
      this.validateName(this.state.lastName) +
      this.validateEmail(this.state.myemail) +
      this.validatePassword() +
      this.validatePasswordMatch();

    if (errors.length > 0) return false;
    var agreed = this.state.agree;

    return agreed;
  }

  validateName(name) {
    var regex = /^[-a-zA-Z ]{2,30}$/;
    if (regex.test(name.trim())) {
      return "";
    } else {
      return "Names must be 2 at least 2 characters long, and contain only letters, spaces and hyphens";
    }
  }

  validateEmail() {
    const email = this.state.myemail;
    var regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (regex.test(email.trim())) {
      return "";
    } else {
      return "please enter a valid email";
    }
  }

  signUp = async (event) => {
    this.setState({ isLoading: true });
    try {
      await Auth.signUp({
        email: this.state.myemail,
        username: this.state.myemail,
        password: this.state.mypassword,
        attributes: {
          email: this.state.myemail,
          given_name: this.state.firstName,
          family_name: this.state.lastName,
        },
      });
      this.setState({
        phase: "signUpCode",
        isLoading: false,
      });
    } catch (e) {
      if (e.message.indexOf("already exists") > 0) {
        const email = this.state.myemail;
        // lets check to see if authenticated
        try {
          await Auth.resendSignUp(email);
          this.setState({
            isLoading: false,
            phase: "signUpCode",
          });
          this.props.showErrorMessage(
            "An account with this email already exists, " +
              "however we have sent you a new validation code if you believe you haven't activated your account yet.",
            false
          );
        } catch (e2) {
          if (e2.message.indexOf("already")) {
            this.setState({
              phase: "signUpCode",
              isLoading: false,
            });
            this.props.showErrorMessage("The user already exists", false);
          } else
            this.setState({
              isLoading: false,
            });
          this.props.showErrorMessage(e2.message, false);
        }
      } else {
        this.setState({
          isLoading: false,
        });
        this.props.showErrorMessage(e.message, false);
      }
    }
  };

  renderSignUp() {
    const firstNameError = this.validateName(this.state.firstName);
    const lastNameError = this.validateName(this.state.lastName);
    const emailError = this.validateEmail(this.state.myemail);
    const password1Error = this.validatePassword();
    const password2Error = this.validatePasswordMatch();
    const formIsValid = this.signUpformIsValid();
    let agreeText = "I am 13 or over and agree";
    if (this.props.under13Allowed) agreeText = "I agree";

    return (
      <form name="signUpForm">
        {this.renderBackToLogin()}
        <h4>Sign Up</h4>
        <div class="form-group">
          <label>Email:</label>
          <JInput
            type="email"
            class="form-control"
            id="myemail"
            value={this.state.myemail}
            onChange={this.handleChange}
          />
          {this.state.myemail.length > 0 && (
            <div class="text-danger">{emailError}</div>
          )}
        </div>
        <div class="form-group">
          <label for="firstName">First Name:</label>
          <JInput
            type="text"
            class="form-control"
            id="firstName"
            value={this.state.firstName}
            onChange={this.handleChange}
          />
          {this.state.firstName.length > 0 && (
            <div class="text-danger">{firstNameError}</div>
          )}
        </div>
        <div class="form-group">
          <label for="lastName">Last Name:</label>
          <JInput
            type="text"
            class="form-control"
            id="lastName"
            value={this.state.lastName}
            onChange={this.handleChange}
          />
          {this.state.lastName.length > 0 && (
            <div class="text-danger">{lastNameError}</div>
          )}
        </div>
        <div class="form-group">
          <label for="mypassword">Password:</label>
          <JInput
            type="password"
            class="form-control"
            id="mypassword"
            value={this.state.mypassword}
            onChange={this.handleChange}
          />
          {this.state.mypassword.length > 0 && (
            <div class="text-danger">{password1Error}</div>
          )}
        </div>
        <div class="form-group">
          <label for="mypassword2">Retype Password:</label>
          <JInput
            type="password"
            class="form-control"
            id="mypassword2"
            value={this.state.mypassword2}
            onChange={this.handleChange}
          />
          {this.state.mypassword2.length > 0 && (
            <div class="text-danger">{password2Error}</div>
          )}
        </div>
        <div class="form-group">
          <input
            id="agree"
            type="checkbox"
            checked={this.state.agree}
            onChange={this.handleChange}
          />
          <span> </span>
          {agreeText} to the <span> </span>
          <a href="#" onClick={() => this.props.showPrivacyPolicy()}>
            Privacy Policy
          </a>
          <span> </span>
          and <span> </span>
          <a href="#" onClick={() => this.props.showTermsOfService()}>
            Terms of Service
          </a>
          <span> </span>
          related to using this site.
        </div>
        <LoaderButton
          disabled={!formIsValid}
          variant="success"
          onClick={(e) => this.signUp(e)}
          text="Submit"
          loadingText="Submitting..."
          isLoading={this.state.isLoading}
        />{" "}
        <Button variant="danger" onClick={this.props.clickBackToLogin}>
          Cancel
        </Button>
      </form>
    );
  }

  async verifySignUpCode() {
    this.setState({ isLoading: true });
    try {
      let info = await Auth.confirmSignUp(
        this.state.myemail,
        this.state.verificationCode
      );

      if (this.props.onAccountCreated)
        return this.props.onAccountCreated(this.state.myemail);

      let signedIn = false;
      try {
        await Auth.signIn(this.state.myemail, this.state.mypassword);
        signedIn = true;
      } catch (e) {
        let text = e.message;
        if (text.toLowerCase().indexOf("incorrect") >= 0) {
          this.props.showErrorMessage(
            "It looks like your accout is now validated, but you have entered a different password to your first attempt.  Try logging in with your old password, or click on 'forgot password' to reset it.",
            false
          );
          this.props.clickBackToLogin();
        } else {
          this.props.showErrorMessage(e.message, false);
        }
      }
      if (signedIn) {
        var user = await Auth.currentUserInfo();

        var input = {
          id: user.id,
          email: this.state.myemail,
          firstName: this.state.firstName,
          lastName: this.state.lastName,
          accountProvider: "cognito",
        };

        await this.props.createNewProfile(input, this.props.onLogin);

        this.setState({ isLoading: false });

        this.props.history.push("/" + this.props.onLogin);
      }
    } catch (e) {
      if (e.message.indexOf("CONFIRMED") > 0) {
        // this account has already been confirmed
        this.props.showErrorMessage(
          "It looks like your account has already been validated.  Nice! Try logging in with your old password, or click on 'forgot password' to reset it.",
          false
        );
        this.props.clickBackToLogin();
      } else {
        this.props.showErrorMessage(e.message, false);
      }
      this.setState({
        isLoading: false,
      });
    }
  }

  renderSignUpCode() {
    return (
      <div>
        {this.renderBackToLogin()}
        <h4>Sign Up</h4>
        <p>
          You have been sent a verification code to your email address (
          {this.state.myemail}
          ). Please enter this code below:
        </p>
        <form name="verificationForm">
          <div class="form-group">
            <label for="myemail">Code:</label>
            <JInput
              type="text"
              class="form-control"
              id="verificationCode"
              value={this.state.verificationCode}
              onChange={this.handleChange}
            />
          </div>
          <LoaderButton
            className="btn-block"
            variant="success"
            onClick={(e) => this.verifySignUpCode(e)}
            text="Submit"
            loadingText="Submitting..."
            isLoading={this.state.isLoading}
          />
          <Button
            variant="danger"
            className="btn-block"
            onClick={this.props.clickBackToLogin}
          >
            Cancel
          </Button>
        </form>
      </div>
    );
  }

  render() {
    return (
      <div style={{ marginBottom: "10px" }}>
        {this.state.phase === "signUp" && this.renderSignUp()}
        {this.state.phase === "signUpCode" && this.renderSignUpCode()}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  userProfileLoginState: state.userProfile.loginState,
});

export default connect(mapStateToProps, {
  showErrorMessage,
  createNewProfile,
  showPrivacyPolicy,
  showTermsOfService,
})(NewAccountPanel);
