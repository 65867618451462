/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createAgileLifeUser = /* GraphQL */ `
  mutation CreateAgileLifeUser(
    $input: CreateAgileLifeUserInput!
    $condition: ModelagileLifeUserConditionInput
  ) {
    createAgileLifeUser(input: $input, condition: $condition) {
      id
      accountProvider
      email
      firstName
      lastName
      profileImage
    }
  }
`;
export const updateAgileLifeUser = /* GraphQL */ `
  mutation UpdateAgileLifeUser(
    $input: UpdateAgileLifeUserInput!
    $condition: ModelagileLifeUserConditionInput
  ) {
    updateAgileLifeUser(input: $input, condition: $condition) {
      id
      accountProvider
      email
      firstName
      lastName
      profileImage
    }
  }
`;
export const deleteAgileLifeUser = /* GraphQL */ `
  mutation DeleteAgileLifeUser(
    $input: DeleteAgileLifeUserInput!
    $condition: ModelagileLifeUserConditionInput
  ) {
    deleteAgileLifeUser(input: $input, condition: $condition) {
      id
      accountProvider
      email
      firstName
      lastName
      profileImage
    }
  }
`;
export const createLens = /* GraphQL */ `
  mutation CreateLens(
    $input: CreateLensInput!
    $condition: ModellensConditionInput
  ) {
    createLens(input: $input, condition: $condition) {
      id
      name
      shortDescription
      whenToUse
      keywords
      filterCharacteristics
    }
  }
`;
export const updateLens = /* GraphQL */ `
  mutation UpdateLens(
    $input: UpdateLensInput!
    $condition: ModellensConditionInput
  ) {
    updateLens(input: $input, condition: $condition) {
      id
      name
      shortDescription
      whenToUse
      keywords
      filterCharacteristics
    }
  }
`;
export const deleteLens = /* GraphQL */ `
  mutation DeleteLens(
    $input: DeleteLensInput!
    $condition: ModellensConditionInput
  ) {
    deleteLens(input: $input, condition: $condition) {
      id
      name
      shortDescription
      whenToUse
      keywords
      filterCharacteristics
    }
  }
`;
export const createTool = /* GraphQL */ `
  mutation CreateTool(
    $input: CreateToolInput!
    $condition: ModeltoolConditionInput
  ) {
    createTool(input: $input, condition: $condition) {
      id
      name
      shortDescription
      whenToUse
      keywords
    }
  }
`;
export const updateTool = /* GraphQL */ `
  mutation UpdateTool(
    $input: UpdateToolInput!
    $condition: ModeltoolConditionInput
  ) {
    updateTool(input: $input, condition: $condition) {
      id
      name
      shortDescription
      whenToUse
      keywords
    }
  }
`;
export const deleteTool = /* GraphQL */ `
  mutation DeleteTool(
    $input: DeleteToolInput!
    $condition: ModeltoolConditionInput
  ) {
    deleteTool(input: $input, condition: $condition) {
      id
      name
      shortDescription
      whenToUse
      keywords
    }
  }
`;
