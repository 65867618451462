import React, { Component } from "react";
import Fade from "react-reveal/Fade";

export default class SliderSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = { show: false };
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    this.setState({ show: !this.state.show });
  }
  render() {
    return (
      <div>
        <Fade left collapse when={this.state.show}>
          {this.props.content}
        </Fade>
        <div
          style={{
            textAlign: "right",
            marginTop: "10px",
            marginBottom: "10px",
          }}
        >
          <button
            className="btn btn-success"
            type="button"
            onClick={this.handleClick}
          >
            {this.state.show ? "Show Less" : "Show More"}
          </button>
        </div>
      </div>
    );
  }
}
