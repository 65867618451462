import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import TeamImage from "./images/background.png";
import "./backgroundAnimation.css";
import { TweenLite, Circ } from "gsap";
var points, target, xspacing, yspacing;

// Event handling

function mouseMove(e) {
  var posx = 0;
  var posy = 0;

  if (e.clientX || e.clientY) {
    posx = e.clientX;
    posy = e.clientY;
  } else if (e.pageX || e.pageY) {
    posx = e.pageX;
    posy = e.pageY;
  }

  target.x = posx;
  target.y = posy;
}

function resize() {
  /*width = window.innerWidth;
    height = window.innerHeight;
    largeHeader.style.height = height+'px';
    canvas.width = width;
    canvas.height = height;*/
}

function animate(ctx) {
  for (var i in points) {
    // detect points in range
    if (Math.abs(getDistance(target, points[i])) < 4000) {
      points[i].active = 0.5;
      points[i].circle.active = 0.7;
    } else if (Math.abs(getDistance(target, points[i])) < 20000) {
      points[i].active = 0.3;
      points[i].circle.active = 0.5;
    } else if (Math.abs(getDistance(target, points[i])) < 40000) {
      points[i].active = 0.1;
      points[i].circle.active = 0.2;
    } else {
      points[i].active = 0.05;
      points[i].circle.active = 0.1;
    }

    drawLines(ctx, points[i]);
    points[i].circle.draw(ctx);
  }
}

function shiftPoint(p) {
  TweenLite.to(p, 1 + 1 * Math.random(), {
    x: p.originX - xspacing / 2 + Math.random() * xspacing,
    y: p.originY - yspacing / 2 + Math.random() * yspacing,
    ease: Circ.easeInOut,
    onComplete: function () {
      shiftPoint(p);
    },
  });
}

// Canvas manipulation
function drawLines(ctx, p) {
  if (!p.active) return;
  for (var i in p.closest) {
    ctx.beginPath();
    ctx.moveTo(p.x, p.y);
    ctx.lineTo(p.closest[i].x, p.closest[i].y);
    ctx.strokeStyle = "rgba(92,83,234," + p.active + ")";
    //ctx.strokeStyle = "rgba(234,234,234," + p.active + ")";
    ctx.stroke();
  }
}

function Circle(pos, rad, color) {
  var _this = this;

  // constructor
  (function () {
    _this.pos = pos || null;
    _this.radius = rad || null;
    _this.color = color || null;
  })();

  this.draw = function (ctx) {
    if (!_this.active) return;
    ctx.beginPath();
    ctx.arc(_this.pos.x, _this.pos.y, _this.radius, 0, 4 * Math.PI, false);
    ctx.fillStyle = "rgba(92,83,234," + _this.active + ")";
    ctx.fill();
  };
}

// Util
function getDistance(p1, p2) {
  return Math.pow(p1.x - p2.x, 2) + Math.pow(p1.y - p2.y, 2);
}

export default class BackgroundNodeAnimation extends Component {
  constructor(props) {
    super(props);
    this.updateCanvas = this.updateCanvas.bind(this);
    this.updateAnimationState = this.updateAnimationState.bind(this);
  }

  componentDidMount() {
    this.initialise();
    this.rAF = requestAnimationFrame(this.updateAnimationState);

    // add listeners
    if (!("ontouchstart" in window)) {
      window.addEventListener("mousemove", mouseMove);
    }
    window.addEventListener("resize", resize);
  }

  componentWillUnmount() {
    cancelAnimationFrame(this.rAF);
  }

  updateAnimationState() {
    this.updateCanvas();
    this.rAF = requestAnimationFrame(this.updateAnimationState);
  }

  initialise() {
    let width = window.innerWidth;
    let height = window.innerHeight;
    let canvas = this.refs.canvas;
    canvas.width = width;
    canvas.height = height;

    target = { x: width / 2, y: height / 2 };
    // create points
    xspacing = width / 10;
    if (xspacing < 100) xspacing = 100;
    yspacing = height / 20;
    if (yspacing < 50) yspacing = 50;

    points = [];
    for (var x = 0; x < width; x = x + xspacing) {
      for (var y = 0; y < height; y = y + height / 20) {
        var px = x + Math.random() * xspacing;
        var py = y + Math.random() * xspacing;
        var p = { x: px, originX: px, y: py, originY: py };
        points.push(p);
      }
    }

    // for each point find the 5 closest points
    for (var i = 0; i < points.length; i++) {
      var closest = [];
      var p1 = points[i];
      for (var j = 0; j < points.length; j++) {
        var p2 = points[j];
        if (!(p1 == p2)) {
          var placed = false;
          for (var k = 0; k < 3; k++) {
            if (!placed) {
              if (closest[k] == undefined) {
                closest[k] = p2;
                placed = true;
              }
            }
          }

          for (var k = 0; k < 3; k++) {
            if (!placed) {
              if (getDistance(p1, p2) < getDistance(p1, closest[k])) {
                closest[k] = p2;
                placed = true;
              }
            }
          }
        }
      }
      p1.closest = closest;
    }

    // assign a circle to each point
    for (var i in points) {
      var c = new Circle(
        points[i],
        2 + Math.random() * 2,
        "rgba(92,83,234,0.3)"
      );
      points[i].circle = c;
    }

    for (var i in points) {
      shiftPoint(points[i]);
    }
  }

  updateCanvas() {
    let ctx = this.refs.canvas.getContext("2d");
    let width = window.innerWidth;
    let height = window.innerHeight;
    let canvas = this.refs.canvas;
    if (canvas.width !== width) canvas.width = width;
    if (canvas.height !== height) canvas.height = height;

    ctx.clearRect(0, 0, width, height);
    animate(ctx);
  }

  render() {
    return (
      <div className="backgroundAnimation">
        <img src={TeamImage} alt="backgroundImage" />
        <canvas ref="canvas"></canvas>
      </div>
    );
  }
}
