import React, { Component } from "react";
import smallCircle from "./images/smallcircle.png";

export default function renderFooter() {
  return (
    <div>
      <div
        className="text-right pageSubheader"
        data-aos="fade"
        data-aos-duration="1000"
        data-aos-offset="20"
      >
        <div className="pageFooter text-right">
          <img src={smallCircle} alt="logo" /> The Agile Life
        </div>
      </div>
    </div>
  );
}
