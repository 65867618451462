import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Scott from "./images/scottHeadshot.png";
import David3 from "./images/davidHeadshot.png";
import Karen from "./images/karenHeadshot.png";
import Third from "./images/thirdPerson.png";
import smallCircle from "./images/smallcircle.png";
import SliderSection from "../components/CommonWidgets/SlideUpDownPanel/SliderSection";
import titleHeader from "../components/headers/titleHeader";
import renderFooter from "../components/headers/footer";

export default function AboutUs() {
  function renderHeader() {
    return titleHeader(
      "About Us",
      "Meet our team, who share a common passion for helping individuals, teams, and organisations to flourish."
    );
  }

  return (
    <div>
      {renderHeader()}
      <div style={{ borderTop: "3px solid white" }}>
        <div
          className="blackOnWhiteText paddedTopAndBottom"
          style={{
            overflowX: "hidden",
            overflowY: "hidden",
            marginTop: "15px",
          }}
        >
          <Container>
            <Row>
              <Col className="profileImage" md={2}>
                <div className="d-none d-md-block">
                  <img className="profileImage " src={Scott} alt="Scott" />
                </div>
              </Col>
              <Col md={8} className="profileText">
                <h4>Dr Scott Bolland (director)</h4>
                <img
                  className="profileImage d-block d-md-none"
                  src={Scott}
                  alt="Scott"
                  style={{
                    float: "left",
                    height: "100px",
                    marginRight: "10px",
                  }}
                />
                <p>
                  Dr Scott Bolland is an executive coach, international speaker,
                  facilitator and futurist. His PhD and background (of 25 years)
                  are in the area of Cognitive Science - the scientific study of
                  how the mind works, spanning areas such as psychology,
                  neuroscience, philosophy and artificial intelligence. His
                  passion is playing in the intersection between these areas, in
                  particular how to best prepare individuals, teams, schools and
                  organisations to flourish in the digital age.
                </p>
                <p>
                  His expertise and interests fall into two main areas: people
                  and technology.
                </p>
                <SliderSection content={scottFull} />
              </Col>
            </Row>
            <Row>
              <Col className="profileImage" md={2}>
                <div className="d-none d-md-block">
                  <img className="profileImage " src={David3} alt="David3" />
                </div>
              </Col>
              <Col md={8} className="profileText">
                <h4>David Gunsberg</h4>

                <img
                  className="profileImage d-block d-md-none"
                  src={David3}
                  alt="David3"
                  style={{
                    float: "left",
                    height: "100px",
                    marginRight: "10px",
                  }}
                />
                <p>
                  David has more than 25 years’ experience in corporate
                  strategy, consumer marketing and customer experience. During
                  this time he spent 12 years in the technology sector as an
                  executive at Telstra where he held roles in service delivery
                  management, marketing, customer acquisition and customer
                  experience.
                </p>
                <p>
                  After leaving Telstra he founded a consulting practice
                  focussed on strategy development, customer experience,
                  organisational design, organisational governance, project
                  management and business process design. In this role he worked
                  with large organisations including Government Agencies,
                  Recruitment Firms, Universities and, at a smaller scale,
                  Remote Indigenous Groups. He also served as Chair of Career
                  Keys, a Charity focussed on providing employment and education
                  services to some of the most disadvantaged communities in
                  Brisbane.
                </p>
                <SliderSection content={davidFull} />
              </Col>
            </Row>
            <Row>
              <Col className="profileImage" md={2}>
                <div className="d-none d-md-block">
                  <img className="profileImage " src={Karen} alt="David3" />
                </div>
              </Col>
              <Col md={8} className="profileText">
                <h4>Karen Schulz</h4>

                <img
                  className="profileImage d-block d-md-none"
                  src={Karen}
                  alt="Karen"
                  style={{
                    float: "left",
                    height: "100px",
                    marginRight: "10px",
                  }}
                />
                <p>
                  Karen’s experience in cultural transformation and business
                  performance is grounded in over 25 years of proven performance
                  working nationally and internationally as a senior leader,
                  executive coach, consultant, facilitator, university lecturer
                  and cultural and strategic transformation change agent.
                  Karen’s natural style has enabled Karen to design practical
                  and business suitable programs implemented through customised
                  approaches designed to build internal capability, ownership
                  and deliver sustained benefits for people and the
                  organisation.
                </p>

                <SliderSection content={karenFull} />
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      {renderFooter()}
    </div>
  );
}

const scottFull = (
  <div>
    <p>
      People and peak performance were the subject of Scott's early academic
      years. Working alongside global experts he developed a deep understanding
      of the psychology and neuroscience of memory, learning and creativity.
      Driven by the profound realisation that people are at the centre of
      performance Scott began to investigate the science of human flourishing,
      and this remains the field that he is most passionate about today. Over
      the years he has brought the latest research backed insights from Positive
      Psychology, leadership, and high-performance teamwork to his clients.
      Scott has worked with diverse groups including MBA students, the military,
      police services, resource companies, education providers and financial
      institutions. He has delivered large scale Positive Psychology based
      events to 1000s of school and corporate staff, utilising a compelling mix
      of experiential and rich multimedia environments including live music as a
      way to "challenge the mind, and inspire the soul."
    </p>
    <p>
      Scott's main interest in technology is the application of rapidly emerging
      Artificial Intelligence platforms and techniques for the betterment of
      society. His company, <i>New Dawn Technologies</i>, which he founded with
      Sean Howard (one of Australia's leading tech entrepreneurs), utilises AI
      to help personalise education, to improve student engagement and learning
      outcomes.
    </p>
    <p>
      Scott’s TEDx talk entitled "Neuroscience, AI, and the Future of Education"
      has been seen by close to 100,000 viewers, and has led to him consulting
      for Ministries of Education across the globe and running strategy
      workshops for the Education Policy makers of the European Commission.
    </p>
    <p>
      Scott brings with him years of practical experience in corporate education
      and executive coaching coupled with a deep academic background the future
      of work, and the science of human flourishing.
    </p>
  </div>
);

const davidFull = (
  <div>
    <p>
      David moved into the Higher Education sector full time in 2014 where he
      spent 5 years as the Director Planning & Engagement for Griffith
      University. In this role David professionalised the Project Management
      workforce and formalised project delivery around the PRINCE2, Project
      Portfolio Management and Agile methodologies. During this time David spoke
      extensively at sector leadership forums about the importance of customer
      experience.{" "}
    </p>

    <p>
      In 2019 David left Griffith to reopen his consulting practice, this time
      with a focus on helping other educational institutions lead through
      uncertainty through the use of agile approaches to strategy formulation
      and operational delivery. He has worked with Griffith University,
      University of Southern Queensland, Queensland University of Technology,
      Central Queensland University as well as a number of community groups
      supporting marginalised voices.
    </p>

    <p>
      David has written and spoken academically on the link between
      organisational agility and excellent customer experience. He also
      continues work on an applied framework for understanding organisational
      complexity in pursuit of delivering simplicity for customers.
    </p>

    <p>
      David is also an award-winning advocate for diversity and inclusion, a
      proud father to twins and an ever-hopeful Richmond Tigers fan.
    </p>

    <p>
      He holds a Bachelor of Arts from UQ and Masters Degree in Management from
      ANU.
    </p>
  </div>
);

const karenFull = (
  <div>
    <p>
      As a neuro coach, Karen utilises the results based coaching system drawing
      from the heart of neuroscience and incorporating the study of change,
      systems theory, learning theory and positive psychology. As an experienced
      ACC Credentialed Executive and Leadership coach with 2500 coaching hours,
      Karen has a proven track record of effectively supporting leaders to
      rapidly identify and understand underlying mindsets, beliefs and
      behaviours and supports them to make significant behavioural shifts.
    </p>
  </div>
);
