let ppIsVisible = false;
let tosIsVisible = false;

class MessageServer {
  _setListener(parent) {
    this.parent = parent;
  }

  _showOKMessage(message) {
    this.parent.setState({
      okMessageVisible: true,
      message: message
    });
    let _this = this;
    return new Promise(function(resolve, reject) {
      _this.okPromise = resolve;
    });
  }

  _okMessageClosed() {
    this.parent.setState({
      okMessageVisible: false
    });
    this.okPromise();
  }

  _showErrorMessage(message) {
    this.parent.setState({
      errorMessageVisible: true,
      message: message
    });
    let _this = this;
    return new Promise(function(resolve, reject) {
      _this.errorPromise = resolve;
    });
  }

  _errorMessageClosed() {
    this.parent.setState({
      errorMessageVisible: false
    });
    this.errorPromise();
  }

  _showConfirmMessage(heading, message) {
    this.parent.setState({
      confirmMessageVisible: true,
      message: message,
      heading: heading
    });
    let _this = this;
    return new Promise(function(resolve, reject) {
      _this.confirmPromise = resolve;
    });
  }

  _confirmMessageOK() {
    this.parent.setState({
      confirmMessageVisible: false
    });
    this.confirmPromise(true);
  }

  _confirmMessageClosed() {
    this.parent.setState({
      confirmMessageVisible: false
    });
    this.confirmPromise(false);
  }

  _showImageSelector(props) {
    //alert("showing image selector " + JSON.stringify(props));
    this.parent.setState({
      imageSelectorVisible: true,
      imageSelectorProps: props
    });
    let _this = this;
    return new Promise(function(resolve, reject) {
      _this.imageSelectorPromise = resolve;
    });
  }

  _imageSelectorClosed() {
    this.parent.setState({
      imageSelectorVisible: false
    });
    this.imageSelectorPromise();
  }

  _showGetDescriptionPopup(heading, message, initialName) {
    this.parent.setState({
      descriptionMessageVisible: true,
      message: message,
      heading: heading,
      initialName: initialName
    });
    let _this = this;
    return new Promise(function(resolve, reject) {
      _this.confirmPromise = resolve;
    });
  }

  _descriptionMessageOK(vars) {
    this.parent.setState({
      descriptionMessageVisible: false
    });
    this.confirmPromise(vars);
  }

  _descriptionMessageClosed(vars) {
    this.parent.setState({
      descriptionMessageVisible: false
    });
    this.confirmPromise(false);
  }

  _tosIsVisible() {
    return tosIsVisible;
  }

  _ppIsVisible() {
    return ppIsVisible;
  }

  _setTosVisible(v) {
    tosIsVisible = v;
    this.parent.forceUpdate();
  }

  _setppIsVisible(v) {
    ppIsVisible = v;
    this.parent.forceUpdate();
  }
}

export default new MessageServer();
