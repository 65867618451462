import React, { Component } from "react";
import Header from "./components/headers/header";
import Homepage from "./pages/homepage";
import AboutUs from "./pages/aboutus";
import ContactUs from "./pages/ContactUs";
import Services from "./pages/services";
import MemberLogin from "./pages/memberLogin";
import FreeStuff from "./pages/freestuff";
import FreeNewsletter from "./pages/newsletter";
import Background from "./components/BackgroundNodeAnimation";
import MessageServer from "./containers/sharedPopupMenus/PopupMessages/MessageServer";
import PrivacyPolicyPopup from "./components/policyDocuments/PrivacyPolicyPopup";
import TermsOfServicePopup from "./components/policyDocuments/TermsOfServicePopup";
import OkMessagePopup from "./containers/sharedPopupMenus/PopupMessages/OkMessagePopup";
import ErrorMessagePopup from "./containers/sharedPopupMenus/PopupMessages/ErrorMessagePopup";
import ConfirmMessagePopup from "./containers/sharedPopupMenus/PopupMessages/ConfirmMessagePopup";
import ImageSelectorPopup from "./containers/sharedPopupMenus/PopupMessages/ImageSelectorPopup";
import DescriptionMessagePopup from "./containers/sharedPopupMenus/PopupMessages/DescriptionMessagePopup";
import { connect } from "react-redux";
import {
  logout,
  checkForAutoLogin,
} from "./reduxStores/actions/userProfileActions";
import MemberArea from "./pages/membershipPages/memberAreaHome";
import AOS from "aos";
import "./pages/css/Pages.css";
import ReactGA from "react-ga";

import FreeWebinar from "./pages/webinar/freewebinar";
import Webinar from "./pages/webinar/webinar";
import Thanks from "./pages/webinar/thanks";

class Main extends Component {
  constructor(props) {
    super(props);
    let activePage = this.checkForActivePage();

    this.state = {
      activePage: activePage,
      isAuthenticated: false,
      isAuthenticating: true,
      hasLoaded: false,
    };
  }

  checkForActivePage() {
    var currenthash = window.location.hash;
    if (!currenthash || currenthash.length === 0) currenthash = "#home";
    return currenthash;
  }

  componentDidMount() {
    this.props.checkForAutoLogin();
    AOS.init({
      // initialise with other settings
      duration: 2000,
    });
    ReactGA.initialize("UA-175490230-1");
    ReactGA.pageview(window.location.pathname + "_" + window.location.hash);
    console.log("WINDOW LOCATION PATHNAME:", window.location.pathname);
    console.log("WINDOW LOCATION SEARCH:", window.location.hash);
    window.addEventListener("hashchange", this.hashChanged, false);
  }

  componentWillUnmount() {
    window.removeEventListener("hashchange", this.hashChanged, false);
  }

  hashChanged = () => {
    const page = window.location.hash;
    ReactGA.pageview(window.location.pathname + "_" + page);
    this.setState({
      activePage: page,
    });
  };

  selectPage(page) {
    this.setState({ activePage: page });
    window.scrollTo(0, 0);
  }

  renderMainSite() {
    return (
      <div>
        <Header
          activePage={this.state.activePage}
          onSelect={(page) => this.setState({ activePage: page })}
        />
        <div className="contents">
          {this.state.activePage === "#home" && (
            <Homepage onSelect={(page) => this.selectPage(page)} />
          )}
          {this.state.activePage === "#about" && <AboutUs />}
          {this.state.activePage === "#services" && (
            <Services onSelect={(page) => this.selectPage(page)} />
          )}
          {this.state.activePage === "#members" && <MemberLogin />}
          {this.state.activePage === "#contact" && <ContactUs />}
          {this.state.activePage === "#book" && <FreeStuff />}
          {this.state.activePage === "#newsletter" && <FreeNewsletter />}
          {this.state.activePage === "#freewebinar" && <FreeWebinar />}
          {this.state.activePage === "#webinar" && <Webinar />}
          {this.state.activePage === "#thanks" && <Thanks />}
        </div>
      </div>
    );
  }

  renderMemberArea() {
    return <MemberArea />;
  }

  render() {
    return (
      <div className="App">
        <div className="backLayer">
          <Background></Background>
        </div>
        <div className="frontLayer">
          {this.props.loggedInState === "USER_LOGGED_OUT" &&
            this.renderMainSite()}
          {this.props.loggedInState === "USER_LOGGED_IN" &&
            this.renderMemberArea()}
        </div>
        {this.state.imageSelectorVisible && (
          <ImageSelectorPopup
            maxWidth={this.state.imageSelectorProps.maxWidth}
            maxHeight={this.state.imageSelectorProps.maxHeight}
            aspect={this.state.imageSelectorProps.aspect}
            onImageUpload={this.state.imageSelectorProps.onImageUpload}
            currentImage={this.state.imageSelectorProps.currentImage}
            onClose={() => MessageServer._imageSelectorClosed()}
          />
        )}

        <OkMessagePopup />
        <ErrorMessagePopup />
        <PrivacyPolicyPopup />
        <TermsOfServicePopup />

        {this.state.confirmMessageVisible && (
          <ConfirmMessagePopup
            message={this.state.message}
            heading={this.state.heading}
            onClose={() => MessageServer._confirmMessageClosed()}
            onOK={() => MessageServer._confirmMessageOK()}
          />
        )}
        {this.state.descriptionMessageVisible && (
          <DescriptionMessagePopup
            message={this.state.message}
            heading={this.state.heading}
            initialName={this.state.initialName}
            onClose={() => MessageServer._descriptionMessageClosed()}
            submit={(vars) => MessageServer._descriptionMessageOK(vars)}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  loggedInState: state.userProfile.loginState,
});

export default connect(mapStateToProps, { checkForAutoLogin, logout })(Main);
