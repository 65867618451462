import React from "react";
import "./LoadingBanner.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default ({
  isLoading,
  text,
  loadingText,
  className = "",
  disabled = false,
  variant,
  ...props
}) => (
  <div className="LoadingBanner verticallyCentredParent">
    <div className="verticallyCentred">
      <h2 style={{ textAlign: "center" }}>
        <FontAwesomeIcon icon="spinner" className="fa-spin" /> Loading...
      </h2>
    </div>
  </div>
);
