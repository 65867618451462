import {
  TOOLKIT_SAVE_LENS,
  TOOLKIT_SAVE_TOOL,
  TOOLKIT_UPDATE_LENS_SEARCH_RESULTS,
  TOOLKIT_UPDATE_TOOL_SEARCH_RESULTS,
  TOOLKIT_LOADED,
} from "../actions/lensAndToolkitDatabaseActions";

const initialState = {
  status: "LOADING",
  allLenses: {},
  lensSearchResults: [],
  allTools: {},
  toolSearchResults: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case TOOLKIT_LOADED:
      return {
        ...state,
        status: TOOLKIT_LOADED,
        allLenses: action.allLenses,
        lensSearchResults: action.lensSearchResults,
        allTools: action.allTools,
        toolSearchResults: action.toolSearchResults,
      };
    case TOOLKIT_SAVE_LENS:
      let lensToSave = action.payload;
      let allLenses = { ...state.allLenses, [lensToSave.id]: lensToSave };

      return {
        ...state,
        allLenses: allLenses,
      };
    case TOOLKIT_SAVE_TOOL:
      let toolToSave = action.payload;
      let allTools = { ...state.allTools, [toolToSave.id]: toolToSave };

      return {
        ...state,
        allTools: allTools,
      };
    case TOOLKIT_UPDATE_LENS_SEARCH_RESULTS:
      return {
        ...state,
        lensSearchResults: action.payload,
      };
    case TOOLKIT_UPDATE_TOOL_SEARCH_RESULTS:
      return {
        ...state,
        toolSearchResults: action.payload,
      };
    default:
      return state;
  }
}
