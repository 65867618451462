import React, { Component } from "react";
import { Container, Tabs, Tab, Button, Row, Col } from "react-bootstrap";
import "./LensAndToolEditors.css";
import ItemList from "../components/lists/ItemList";
import config from "../config";
import LensEditorPopup from "./LensEditorPopup";
import ToolEditorPopup from "./toolEditorPopup";
import { connect } from "react-redux";
import {
  newLens,
  newTool,
  saveLens,
  saveTool,
  findLenses,
  findTools,
} from "../reduxStores/actions/lensAndToolkitDatabaseActions";
import KeywordSearch from "../components/search/keywordSearch";

class LensAndToolEditors extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedLensToEdit: null,
      lensEditorVisible: false,
      selectedToolToEdit: null,
      toolEditorVisible: false,
      lensKeywords: [],
      toolKeywords: [],
    };
  }

  updateKeywords(newList, type) {
    if (type === "Lens") {
      this.setState({
        lensKeywords: newList,
      });
      this.props.findLenses(newList);
    } else if (type === "Tool") {
      this.setState({
        toolKeywords: newList,
      });
      this.props.findTools(newList);
    }
  }

  lensSelected(lens) {
    this.setState({
      selectedLensToEdit: lens,
      lensEditorVisible: true,
    });
  }

  saveAndCloseLensEditor(lens) {
    this.props.saveLens(lens);
    this.setState({
      lensEditorVisible: false,
    });
  }

  closeLensEditor() {
    this.setState({
      lensEditorVisible: false,
    });
  }

  createNewLens() {
    this.setState({
      selectedLensToEdit: newLens(),
      lensEditorVisible: true,
    });
  }

  toolSelected(tool) {
    this.setState({
      selectedToolToEdit: tool,
      toolEditorVisible: true,
    });
  }

  closeToolEditor() {
    this.setState({
      toolEditorVisible: false,
    });
  }

  createNewTool() {
    this.setState({
      selectedToolToEdit: newTool(),
      toolEditorVisible: true,
    });
  }

  saveAndCloseToolsEditor(tool) {
    this.props.saveTool(tool);
    this.setState({
      toolEditorVisible: false,
    });
  }

  renderSearchTab(name, library, defaultImage, itemList, onSelect, onNew) {
    let keywords = this.state.lensKeywords;
    if (name === "Tool") keywords = this.state.toolKeywords;

    return (
      <div>
        <h2>
          <img className="headerImage" src={defaultImage.resourceId} /> {name}{" "}
          Editor
        </h2>
        <p>
          Use the interface below to search for an existing {name} <b>OR</b>{" "}
          <Button onClick={onNew}>Create a New {name}</Button>
        </p>

        <hr></hr>
        <Row>
          <Col xs={4}>
            <h4>Search for a {name}</h4>
            <KeywordSearch
              keywords={keywords}
              onChange={(newList) => this.updateKeywords(newList, name)}
              library={library}
            />
          </Col>
          <Col xs={8}>
            <h4>Results</h4>
            <ItemList
              items={itemList}
              defaultImage={defaultImage}
              onSelect={onSelect}
            />
          </Col>
        </Row>
      </div>
    );
  }

  render() {
    let lensList = this.props.lensSearchResults.map(
      (info) => this.props.lensList[info.id]
    );
    let toolList = this.props.toolSearchResults.map(
      (info) => this.props.toolList[info.id]
    );

    return (
      <div className="LensAndToolEditor">
        <Container>
          <h1>Lens and Toolkit Editor</h1>
          <Tabs defaultActiveKey="lenses" id="lensAndToolEditor">
            <Tab eventKey="lenses" title="Lenses" className="whitePanel">
              {this.renderSearchTab(
                "Lens",
                "allLenses",
                config.defaultImages.lens,
                lensList,
                (lens) => this.lensSelected(lens),
                () => this.createNewLens()
              )}
            </Tab>
            <Tab eventKey="tools" title="Tools" className="whitePanel">
              {this.renderSearchTab(
                "Tool",
                "allTools",
                config.defaultImages.tools,
                toolList,
                (tool) => this.toolSelected(tool),
                () => this.createNewTool()
              )}
            </Tab>
          </Tabs>
        </Container>
        {this.state.lensEditorVisible && (
          <LensEditorPopup
            currentLens={this.state.selectedLensToEdit}
            onClose={() => this.closeLensEditor()}
            onSave={(lens) => this.saveAndCloseLensEditor(lens)}
          />
        )}
        {this.state.toolEditorVisible && (
          <ToolEditorPopup
            currentTool={this.state.selectedToolToEdit}
            onClose={() => this.closeToolEditor()}
            onSave={(lens) => this.saveAndCloseToolsEditor(lens)}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  lensList: state.lensAndToolDatabase.allLenses,
  toolList: state.lensAndToolDatabase.allTools,
  lensSearchResults: state.lensAndToolDatabase.lensSearchResults,
  toolSearchResults: state.lensAndToolDatabase.toolSearchResults,
});

export default connect(mapStateToProps, {
  saveLens,
  saveTool,
  findLenses,
  findTools,
})(LensAndToolEditors);
