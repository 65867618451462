import React, { Component } from "react";

export default class JInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      readonly: true,
    };
    this.showInput = this.showInput.bind(this);
  }

  showInput() {
    this.setState({
      readonly: false,
    });
  }

  render() {
    if (this.state.readonly)
      return (
        <input
          type={this.props.type}
          class={this.props.class}
          id={this.props.id}
          value={this.props.value}
          onChange={this.props.onChange}
          onFocus={this.showInput}
          onBlur={this.showInput}
          onClick={this.showInput}
          placeholder={this.props.placeholder}
          readOnly
        />
      );

    return (
      <input
        type={this.props.type}
        class={this.props.class}
        id={this.props.id}
        value={this.props.value}
        onChange={this.props.onChange}
        placeholder={this.props.placeholder}
      />
    );
  }
}
