import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Scott from "./images/scott.png";
import David3 from "./images/david3.png";
import Third from "./images/thirdPerson.png";
import smallCircle from "./images/smallcircle.png";
import SliderSection from "../components/CommonWidgets/SlideUpDownPanel/SliderSection";
import titleHeader from "../components/headers/titleHeader";
import renderFooter from "../components/headers/footer";
import Mailchimp from "../libs/Mailchimp";
import bookCover from "./images/mockupFinal.jpg";

export default function FreeStuff() {
  function renderHeader() {
    return titleHeader(
      "Newsletter",
      "Sign up to our mailing list to receive our free weekly newsletter and videos."
    );
  }

  return (
    <div>
      {renderHeader()}
      <div style={{ borderTop: "3px solid white" }}>
        <div
          className="blackOnWhiteText paddedTopAndBottom"
          style={{
            overflowX: "hidden",
            overflowY: "hidden",
            marginTop: "15px",
          }}
        >
          <Container>
            <Row>
              <Col md={3} />
              <Col md={6}>
                <h4>FREE NEWSLETTER</h4>
                <p>
                  Interested in learning practical skills to help you, your
                  family, your students, your team or your organisation thrive
                  in these challenging and uncertain times?
                </p>
                <p>
                  Subscribe to our mailing list below to receive our weekly
                  newsletter and videos, packed full of practical evidence-based
                  strategies.
                </p>
                <h4>Subscribe</h4>

                <Mailchimp
                  action="https://qut.us9.list-manage.com/subscribe/post?u=c087bef7a98ad8e4f85890553&amp;id=eb1fd76d0a"
                  fields={[
                    {
                      name: "EMAIL",
                      placeholder: "Email",
                      type: "email",
                      required: true,
                    },
                    {
                      name: "FNAME",
                      placeholder: "First name",
                      type: "text",
                      required: true,
                    },
                    {
                      name: "LNAME",
                      placeholder: "Last name",
                      type: "text",
                      required: true,
                    },
                  ]}
                  messages={{
                    sending: "Sending...",
                    success:
                      "Thank you for subscribing!  You will receive a confirmation email shortly, where you can download our free eBook :)  Please check your spam folder if it doesn't arrive in the near future.",
                    error: "An unexpected internal error has occurred.",
                    empty: "Please enter all fields.",
                    duplicate: "You have already subscribed to this newsletter",
                    button: "Subscribe!",
                  }}
                  className={"emailSignup"}
                />
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      {renderFooter()}
    </div>
  );
}
